<template>
  <v-container fluid class="ma-0 pa-0">
      <!--<iframe src="https://api.test.tinygs.com/worldmap/" style="height:60vh;width:100%;" ></iframe>-->

 
  <p></p> 

  <v-card
    class="mx-auto"
    elevation="24"
    max-width="444"
  >
       <v-carousel
    height="344"
   :show-arrows="false"
    cycle
    hide-delimiter-background
  >
    <v-carousel-item
      v-for="(item,i) in items"
      :key="i"
      :src="item.src"
      cover
      max-width="1500"
    ></v-carousel-item>

 
  </v-carousel>
</v-card>
 

    <v-container class="grey--text text--darken-3" >

      <v-container class="grey--text text--darken-3" v-if="noUser">    
      <h2 class="grey--text text--darken-3 mb-4">Unleash Your Inner Explorer: Build a Tiny Ground Station and Help Secure Our Space Future</h2>
      <p>Imagine a world where anyone, anywhere can contribute to the advancement of space exploration.A world where a small, inexpensive device you build yourself help prevent a catastrophic event and connect you to the wonders of space exploration and the cutting edge of technology. </p>
      <p>This world is closer than you think.<b>TinyGS</b>, the Open Source Global Satellite Network, empowers you to become a space pioneer – right from your backyard. </p>
      <p>Space exploration is about more than just discovery. It's about pushing the boundaries of human knowledge and ensuring a brighter future for all. With the ever-increasing number of satellites orbiting Earth, the risk of collisions and debris fields – known as Kessler Syndrome – threatens to lock humanity out of space for generations. </p>
      <p>By building your own Tiny Ground Station, a tiny and affordable device powered by open-source technology. This network of citizen scientists, like yourself, acts as a collective space guardian, collecting vital data that helps precisely identify satellites, preventing costly mistakes and ensuring a safe and thriving space environment. </p>
      <p>TinyGS is more than just a network. It's a community passionate about space exploration and building a brighter future. Here, you'll gain access to: </p>
      <ul>
        <li><b>Easy-to-build</b> ground stations using readily available components.</li>
        <li><b>Open-source</b> knowledge and support to get you started. </li>
        <li>The opportunity to be part of a <b>global</b> effort that's shaping the future of space. </li>
      </ul>
      <p></p> <br>
      </v-container>
 
      <v-container class="grey--text text--darken-3" v-else>    
      <h2 class="grey--text text--darken-3 mb-4">Welcome Back, TinyGS Pioneers! Together, We're Shaping the Future of Space </h2>
      <p>The energy is electrifying! With each new Tiny Ground Station built, each byte of data collected, we're weaving a more robust safety net around our space environment. Thanks to your dedication, the dream of a thriving, accessible space is becoming a reality.</p>
      <p></p> 
      </v-container>
 


      <v-layout justify-center>
      <Worldmap v-bind:style="{ height: '60vh', width: responsiveWidth + '%' }" />
      </v-layout>

      <v-layout row wrap class="justify-center py-6">
        <v-flex sm12 md4 pa-4 >
          <v-layout justify-center>
            <v-card class="pa-2 ma-2 rounded-xl stats-card">
              <v-card-text>
                <div class="text-center display-1 "><v-icon x-large class="display-3 pb-3">mdi-account-group</v-icon></div>
                <div class="text-center display-2 ">{{statistics.members.toLocaleString()}}</div>
                <div class="subheading font-weight-bold text-center">Members</div>
              </v-card-text>
            </v-card>
          </v-layout>
        </v-flex>
        <v-flex sm12 md4 pa-4>
          <v-layout justify-center>
            <v-card class="pa-2 ma-2 rounded-xl stats-card">
              <v-card-text>
                <div class="text-center display-1"><v-icon x-large class="display-3 pb-3">mdi-satellite-uplink</v-icon></div>
                <div class="text-center display-2">{{statistics.stations.toLocaleString()}}</div>
                <div class="subheading font-weight-bold text-center">Active Stations</div>
              </v-card-text>
            </v-card>
          </v-layout>
        </v-flex>
        <v-flex sm12 md4 pa-4>
          <v-layout justify-center>
            <v-card class="pa-2 ma-2 rounded-xl stats-card">
              <v-card-text>
                <div class="text-center display-1 "><v-icon x-large class="display-3 pb-3">mdi-file-document-outline</v-icon></div>
                <div class="text-center display-2">{{(statistics.packets + 10000).toLocaleString()}}</div>
                <div class="subheading font-weight-bold text-center">Received Telemetry</div>
              </v-card-text>
            </v-card>
          </v-layout>
        </v-flex>
      </v-layout>

      <h3 class="py-3 pt-6 grey--text text--darken-3">Get Started</h3>
      <p>Join our <a href="https://t.me/joinchat/DmYSElZahiJGwHX6jCzB3Q">Telegram Channel</a>, visit our <a href="https://github.com/G4lile0/tinyGS">GitHub Page</a> and build your station.</p>
      <p> &nbsp; </p><p> &nbsp; </p>
  
    </v-container>
      
  </v-container>
</template>

<script>
import Worldmap from '../components/Worldmap.vue';
const axios = require("axios");

export default {
  name: 'Home',
  components: {
    Worldmap
  
  },
  data() {
    return {
      noUser : false,
      statistics: {},
      items: [

          {
            src: 'https://static.tinygs.com/stationImages/TinyGS_PakalAstroClub_d6102.jpg',
          },
          {
            src: 'https://static.tinygs.com/stationImages/TinyGS_EA8DLJ_256B_0b4b6.jpg',
          },
          {
            src: 'https://static.tinygs.com/stationImages/TinyGS_EA7KFV_34342.jpg',
          },       

          {
            src: 'https://static.tinygs.com/stationImages/TinyGS_PP5ABU_a0554.jpg',
          },

          {
            src: 'https://static.tinygs.com/stationImages/TinyGS_SQ3DHO_TinyGS_ec1d0.jpg',
          },
          {
            src: 'https://static.tinygs.com/stationImages/TinyGS_JL_ESL_01_bc1f1.jpg',
          },          
          {
            src: 'https://static.tinygs.com/stationImages/TinyGS_OX3HI_1_07d68.jpg',
          },          
                  

          
        ],
    }
  },
  computed: {
    isChrome() {
      return navigator.userAgent.toLowerCase().includes('chro');
    },
  responsiveWidth() {
    if (window.innerWidth <= 768) {
      return 100; // Mobile width - 100%
    } else if (window.innerWidth <= 1900) {
        return this.isChrome ? 85 : 85;

    } else {
     // Use a ternary operator for concise conditional assignment
     // return 60; // Desktop width
      return this.isChrome ? 60 : 60;
    }
  },
},
  beforeMount(){
    this.getStatistics()
  },
  methods: {
    async getStatistics() {
      const { data } = await axios.get(`${process.env.VUE_APP_API_ENDPOINT}/v1/statistics`);
      console.log(data);
      this.statistics = data;
      if (!localStorage.userId) this.noUser = true;
    }
  }

}
</script>

<style scoped>
.home {
  height: 100%;
}

h2 {
  text-align: center;
}

.stats-card {
  min-width: 275px;
  margin:0 auto;
}

</style>
