
<script>
import { Bar } from 'vue-chartjs'

export default {
  extends: Bar,
  props: {
    chartdata: {
      type: Object,
      default: null
    },
  },
  data: () => ({
    options: {
      responsive: true,
      maintainAspectRatio: false,
      dataset: {
        barPercentage: 1,
        categoryPercentage: 0.5
      },
      scales: {
        xAxes: [{
          stacked: true,
          scaleLabel: {
            display: true,
            labelString: 'Days of the month'
          }
        }],
        yAxes: [{
          //stacked: true
        }]
      },
      title: {
        text:"Packets in the last 30 days",
        display: true,
      }
    }
    
  }),

  mounted () {
    this.renderChart(this.chartdata, this.options)
  }
}

</script>

<style>

</style>