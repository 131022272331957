<template>
  <v-tooltip right max-width="300">
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          :class="customClass"
          color="grey"
          dark
          v-bind="attrs"
          v-on="on"
          small
        >
          mdi-help-circle
        </v-icon>
      </template>
      <span>{{text}}</span>
    </v-tooltip>
</template>

<script>
export default {
  name: "HelpTooltip",
  props: [
    "text",
    "customClass"
  ],
}
</script>

<style>

</style>