<template>
  <div>
    <l-map
      @popupclose="focusSatellite=null"
      :zoom.sync="zoom"
      :center="center"
      style="height: 100%; width: 100%; z-index:0;"
    >
      <l-tile-layer
        :url="url"
        :attribution="attribution"
      />
      <l-control-layers ref="control"></l-control-layers>
      <l-marker v-for="satellite in satellites" :key="satellite.name" :lat-lng="[satellite.lat, satellite.lng]" :icon="satelliteIcon" @click="focusSatellite=satellite.name">
        <l-popup><h3><router-link :to='`/satellite/${satellite.name}`'>{{satellite.displayName}}</router-link></h3>Showing stations currently listening to this satellite.</l-popup>
        <l-tooltip :class="focusSatellite?'hidden':''" :options="{permanent: true, offset: [12,0], opacity: 0.8}" >{{satellite.displayName}}</l-tooltip>
      </l-marker>
      <l-layer-group  v-if="filteredStation137 && filteredStation137.length > 0"
      layer-type="overlay"
      name="137 stations"
      :visible="true">
      <l-marker v-for="station in filteredStation137" :key="`${station.name}@${station.userId}`" :visible="!focusSatellite || focusSatellite == station.satellite" :lat-lng="station.location" :icon="getStationIcon(station.status)">
        <l-popup>
          <h3><router-link :to='`/station/${station.name}@${station.userId}`'>{{station.name}}</router-link></h3><br>
          <strong>{{(station.status==0)?'Last seen':'Last packet'}}:</strong> {{(station.status==0)?formatDate(station.lastSeen):formatDate(station.lastPacketTime)}} <br>
          <strong>Version:</strong> {{station.version}} <br>
          <strong>Status:</strong> {{(station.status==0)?'Offline':'Online'}} <br>
          <strong>Listening:</strong> {{station.satellite}} <br>
        </l-popup>
      </l-marker>
    </l-layer-group>   
    <l-layer-group  v-if="filteredStation433 && filteredStation433.length > 0"
      layer-type="overlay"
      name="400-440 stations"
      :visible="true">
      <l-marker v-for="station in filteredStation433" :key="`${station.name}@${station.userId}`" :visible="!focusSatellite || focusSatellite == station.satellite" :lat-lng="station.location" :icon="getStationIcon(station.status)">
        <l-popup>
          <h3><router-link :to='`/station/${station.name}@${station.userId}`'>{{station.name}}</router-link></h3><br>
          <strong>{{(station.status==0)?'Last seen':'Last packet'}}:</strong> {{(station.status==0)?formatDate(station.lastSeen):formatDate(station.lastPacketTime)}} <br>
          <strong>Version:</strong> {{station.version}} <br>
          <strong>Status:</strong> {{(station.status==0)?'Offline':'Online'}} <br>
          <strong>Listening:</strong> {{station.satellite}} <br>
        </l-popup>
      </l-marker>
    </l-layer-group>   
    <l-layer-group  v-if="filteredStation900 && filteredStation900.length > 0"
      layer-type="overlay"
      name="868-915 stations"
      :visible="true">
      <l-marker v-for="station in filteredStation900" :key="`${station.name}@${station.userId}`" :visible="!focusSatellite || focusSatellite == station.satellite" :lat-lng="station.location" :icon="getStationIcon(station.status)">
        <l-popup>
          <h3><router-link :to='`/station/${station.name}@${station.userId}`'>{{station.name}}</router-link></h3><br>
          <strong>{{(station.status==0)?'Last seen':'Last packet'}}:</strong> {{(station.status==0)?formatDate(station.lastSeen):formatDate(station.lastPacketTime)}} <br>
          <strong>Version:</strong> {{station.version}} <br>
          <strong>Status:</strong> {{(station.status==0)?'Offline':'Online'}} <br>
          <strong>Listening:</strong> {{station.satellite}} <br>
        </l-popup>
      </l-marker>
    </l-layer-group>      
    <l-layer-group  v-if="filteredStation2400 && filteredStation2400.length > 0"
      layer-type="overlay"
      name="2400 stations"
      :visible="true">
      <l-marker v-for="station in filteredStation2400" :key="`${station.name}@${station.userId}`" :visible="!focusSatellite || focusSatellite == station.satellite" :lat-lng="station.location" :icon="getStationIcon(station.status)">
        <l-popup>
          <h3><router-link :to='`/station/${station.name}@${station.userId}`'>{{station.name}}</router-link></h3><br>
          <strong>{{(station.status==0)?'Last seen':'Last packet'}}:</strong> {{(station.status==0)?formatDate(station.lastSeen):formatDate(station.lastPacketTime)}} <br>
          <strong>Version:</strong> {{station.version}} <br>
          <strong>Status:</strong> {{(station.status==0)?'Offline':'Online'}} <br>
          <strong>Listening:</strong> {{station.satellite}} <br>
        </l-popup>
      </l-marker>
    </l-layer-group>     
    <l-layer-group  v-if="filteredStationNull && filteredStationNull.length > 0"
      layer-type="overlay"
      name="null band stations"
      :visible="true">
      <l-marker v-for="station in filteredStationNull" :key="`${station.name}@${station.userId}`" :visible="!focusSatellite || focusSatellite == station.satellite" :lat-lng="station.location" :icon="getStationIcon(station.status)">
        <l-popup>
          <h3><router-link :to='`/station/${station.name}@${station.userId}`'>{{station.name}}</router-link></h3><br>
          <strong>{{(station.status==0)?'Last seen':'Last packet'}}:</strong> {{(station.status==0)?formatDate(station.lastSeen):formatDate(station.lastPacketTime)}} <br>
          <strong>Version:</strong> {{station.version}} <br>
          <strong>Status:</strong> {{(station.status==0)?'Offline':'Online'}} <br>
          <strong>Listening:</strong> {{station.satellite}} <br>
        </l-popup>
      </l-marker>
    </l-layer-group>   
    <l-control-scale position="bottomleft" :imperial="true" :metric="true"></l-control-scale>
    </l-map>
  </div>
</template>

<script>
import { LMap, LTileLayer, LMarker, LPopup, LTooltip  , LControlScale,  LControlLayers, LLayerGroup } from "vue2-leaflet";
import { icon } from "leaflet";
const axios = require("axios");
import moment from 'moment';

export default {
  name: "Worldmap",
  components: {
    LMap,
    LTileLayer,
    LPopup,
    LMarker,
    LTooltip,
    LControlScale,
    LControlLayers,
    LLayerGroup
  },
  props: ["packet"],
  data() {
    return {
      satelliteIcon: null,
      stationActiveIcon: null,
      stationInactiveIcon: null,
      zoom: 2,
      center: [16, 5],
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      stations: null,
      satellites: null,
      focusSatellite: null,
    };
  },
  beforeMount() {
    this.getSatellites();
    this.getStations();
    this.updateIconSizes(this.zoom);  // Set initial icon sizes
  },
  methods: {
    getStationIcon(status) {
      return status === 0 ? this.stationInactiveIcon : this.stationActiveIcon;
    },
    updateIconSizes(zoomLevel) {
      const baseSize = 8;  // Base size at zoom level 3
      const scaleFactor = 0.95;  // Adjust this factor to your needs
      const newSize = baseSize * Math.pow(scaleFactor, 3 - zoomLevel );

      this.satelliteIcon = this.createIcon(`${process.env.VUE_APP_STATIC_HOST}/satellite_blue.png`, 4 + newSize * 2);
      this.stationActiveIcon = this.createIcon(`${process.env.VUE_APP_STATIC_HOST}/station_icon_green.png`, 8 + newSize);
      this.stationInactiveIcon = this.createIcon(`${process.env.VUE_APP_STATIC_HOST}/station_icon_red.png`, 4 + newSize);
    },
    createIcon(iconUrl, size) {
      return icon({
        iconUrl: iconUrl,
        iconSize: [size, size],
        iconAnchor: [size / 2, size / 2]
      });
    },
    async getStations() {
      const { data } = await axios.get(`${process.env.VUE_APP_API_ENDPOINT}/v1/stations`);
      this.stations = data;
    },
    async getSatellites() {
      const { data } = await axios.get(`${process.env.VUE_APP_API_ENDPOINT}/v1/satellitesWorldmap`);
      this.satellites = data;
    },
    formatDate(time) {
      if (!time) { return "Never" }
      return moment(time).fromNow();
    },
  },
  computed: { 
    filteredStation137() {
      if (!this.stations) return null
      return this.stations.filter(p => typeof p.autoTune === 'number' && p.autoTune === 137 )
    },
    filteredStation433() {
      if (!this.stations) return null
      return this.stations.filter(p => typeof p.autoTune === 'number' && p.autoTune === 433 )
    },
    filteredStation900() {
      if (!this.stations) return null
      return this.stations.filter(p => typeof p.autoTune === 'number' && p.autoTune === 900 )
    },
    filteredStation2400() {
      if (!this.stations) return null
      return this.stations.filter(p => typeof p.autoTune === 'number' && p.autoTune === 2400 )
    },
    filteredStationNull() {
      if (!this.stations) return null
      return this.stations.filter(p =>  p.autoTune === null )
    },
  },
  watch: {
    zoom(newZoom) {
      this.updateIconSizes(newZoom);
    }
  }
};
</script>

<style>
  .leaflet-marker-icon[src*="satellite_blue.png"] {
    z-index: 300 !important;
  }
  .leaflet-marker-icon[src*="station_icon_green.png"] {
    z-index: 200 !important;
  }
  .leaflet-marker-icon[src*="station_icon_red.png"] {
    z-index: 199 !important;
  }
  .hidden {
    display: none;
  }
</style>
