<template>
  <v-container fluid class="ma-0 pa-0">
      <!--<iframe src="https://api.test.tinygs.com/worldmap/" style="height:60vh;width:100%;" ></iframe>-->
    <v-container class="grey--text text--darken-3" >

      <v-container class="grey--text text--darken-3" v-if="noUser">    
        <h3 class="py-3 grey--text text--darken-3">History</h3>
      <p>Initially TinyGS was born under the name ESP32 Fossa Groundstation, it was developed as a "weekend" project for the FossaSAT-1 LoRa satellite. We are passionate about space and created this project to be able to track and use the satellites and to learn and experiment about radio. Currently the network is open to any LoRa satellite and we also support other flying objects that have a compatible radio modulation with our hardware such as FSK, GFSK, MSK, GMSK, LoRa and OOK. And the project was renamed to TinyGS.</p>
      <p>Even though we have no relation with the Fossa team, they inspired this project and we are excited to support their new launched satellites into our network.</p>
      <p>These are the more important moments of the project:</p>
      <ul>
        <li>Nov 28, 2019 ESP32-OLED-Fossa-GroundStation project born.</li>
        <li>Dec 6, 2019 FossaSAT-1 deployed with an Electron rocket by Rocket Lab.</li>
        <li>Dec 10,2019 YL3CT's GS receive the fist LoRa packet from FossaSAT-1</li>
        <li>Sep 28,2020 6U Norby LoRa satellite is deployed with a Soyuz-2-1b launcher</li>
        <li>Oct 11, 2020 KA9ETC's GS receive the first LoRa packet from Norby</li>
        <li>Jan 24, 2021 3x V-R3x sat deployed with a Falcon-9</li>
        <li>Jan 25, 2021 KA9ETC'S GS receive the first LoRa packet from V-R3x</li>
        <li>Feb 14, 2021 New name and web tinyGS.com with a new Beta firmware.</li>
        <li>Feb 28, 2021 First packet received from SD SAT by PA3ARK.</li>
        <li>Mar 20, 2021 First LoRa relay message between two tinyGS stations from K4KDR to N6RFM using SD SAT.</li>
        <li>Jan 13, 2022 Transporter 3 is a dedicated rideshare mission by SpaceX add 6x FossaSAT-2 and SATLLA-2B new Satellites.</li>
        <li>Jan 26, 2022 FEES2 for deployment from the ISS and received form the network.</li>
        <li>May 30, 2022 2x FossaSAT-2E deployed SpaceX (Transporter 5) using a Vigoride spacecraft (Momentus) </li>
 
      </ul>
 
      </v-container>
 
      <v-container class="grey--text text--darken-3" v-else>    
        <h3 class="py-3 grey--text text--darken-3">History</h3>
      <p>Initially TinyGS was born under the name ESP32 Fossa Groundstation, it was developed as a "weekend" project for the FossaSAT-1 LoRa satellite. We are passionate about space and created this project to be able to track and use the satellites and to learn and experiment about radio. Currently the network is open to any LoRa satellite and we also support other flying objects that have a compatible radio modulation with our hardware such as FSK, GFSK, MSK, GMSK, LoRa and OOK. And the project was renamed to TinyGS.</p>
      <p>Even though we have no relation with the Fossa team, they inspired this project and we are excited to support their new launched satellites into our network.</p>
      <p>These are the more important moments of the project:</p>
      <ul>
        <li>Nov 28, 2019 ESP32-OLED-Fossa-GroundStation project born.</li>
        <li>Dec 6, 2019 FossaSAT-1 deployed with an Electron rocket by Rocket Lab.</li>
        <li>Dec 10,2019 YL3CT's GS receive the fist LoRa packet from FossaSAT-1</li>
        <li>Sep 28,2020 6U Norby LoRa satellite is deployed with a Soyuz-2-1b launcher</li>
        <li>Oct 11, 2020 KA9ETC's GS receive the first LoRa packet from Norby</li>
        <li>Jan 24, 2021 3x V-R3x sat deployed with a Falcon-9</li>
        <li>Jan 25, 2021 KA9ETC'S GS receive the first LoRa packet from V-R3x</li>
        <li>Feb 14, 2021 New name and web tinyGS.com with a new Beta firmware.</li>
        <li>Feb 28, 2021 First packet received from SD SAT by PA3ARK.</li>
        <li>Mar 20, 2021 First LoRa relay message between two tinyGS stations from K4KDR to N6RFM using SD SAT.</li>
        <li>Jan 13, 2022 Transporter 3 is a dedicated rideshare mission by SpaceX add 6x FossaSAT-2 and SATLLA-2B new Satellites.</li>
        <li>Jan 26, 2022 FEES2 for deployment from the ISS and received form the network.</li>
        <li>May 30, 2022 2x FossaSAT-2E deployed SpaceX (Transporter 5) using a Vigoride spacecraft (Momentus) </li>
 
      </ul>
      <p> &nbsp; </p> <p> &nbsp; </p> <p> &nbsp; </p> <p> &nbsp; </p>
      </v-container>
 

    </v-container>
      
  </v-container>

</template>

<script>

const axios = require("axios");

export default {
  name: 'Home',
  components: {
    
  
  },
  data() {
    return {
      noUser : false,
      links: [
        {icon:"mdi-github", name:' GitHub',url:"https://github.com/G4lile0/tinyGS"},
        {icon:"mdi-file-document-multiple-outline", name:' Wiki',url:"https://github.com/G4lile0/tinyGS/wiki"},
        {icon:"mdi-map-marker-question-outline", name:' FAQ',url:"https://github.com/G4lile0/tinyGS/wiki/FAQ"},
        {icon:"mdi-forum", name:' Telegram',url:"https://t.me/joinchat/DmYSElZahiJGwHX6jCzB3Q"},
      ],
      statistics: {}
    }
  },
  computed: {
  responsiveWidth() {
    if (window.innerWidth <= 768) {
      return 100; // Mobile width - 100%
    } else {
      return 60; // Desktop width
    }
  },
},
  beforeMount(){
    this.getStatistics()
  },
  methods: {
    async getStatistics() {
      const { data } = await axios.get(`${process.env.VUE_APP_API_ENDPOINT}/v1/statistics`);
      console.log(data);
      this.statistics = data;
      if (!localStorage.userId) this.noUser = true;
    }
  }

}
</script>

<style scoped>
.home {
  height: 100%;
}

h2 {
  text-align: center;
}

.stats-card {
  min-width: 275px;
  margin:0 auto;
}
  
</style>
