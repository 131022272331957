<template>
<v-card class="pa-7 clickable" :to="`/packet/${packet.packetId}`"  :disabled=testPacket(packet) >
  <v-layout row wrap>
    <v-flex xs12 sm6 md6 lg3 xl2>
      <h3><v-icon>$satelliteDark</v-icon> {{getSatName(packet)}} </h3>
      <div>{{`${dateFormat(packet.serverTime)} (${dateSince(packet.serverTime)})`}}</div>
    </v-flex>
    <v-flex xs6 sm3 md3 lg2 xl1>
      <div class="caption grey--text text-center">Mode</div>
      <div class="text-center">{{`${packet.mode}@${packet.freq}`}}</div>
    </v-flex>
    <v-flex xs6 sm4 md2 lg1 xl1>
      <div class="caption grey--text text-center">📻 Power</div>
      <div class="text-center">{{packet.parsed&&packet.parsed.payload&&packet.parsed.payload.tinygsTxPower? packet.parsed.payload.tinygsTxPower.toFixed(0) + "mW" :"- -"}} </div>
    </v-flex>
    <v-flex xs6 sm4 md2 lg1 xl1>
      <div class="caption grey--text text-center">📏 Distance</div>
      <div class="text-center">{{packet.station.distance? packet.station.distance.toFixed(0) + "Km" : "- -"}}</div>
    </v-flex>
    <v-flex xs6 sm4 md2 lg1 xl1>
      <div class="caption grey--text text-center">📐 Elevation</div>
      <div class="text-center">{{packet.station.elevation? packet.station.elevation.toFixed(2) +"º" : "- -" }}</div>
    </v-flex>
    <v-flex xs6 sm4 md2 lg1 xl1>
      <div class="caption grey--text text-center">📶 RSSI</div>
      <div class="text-center">{{packet.station.receptionParams.rssi}} dBm</div>
    </v-flex>
    <v-flex xs6 sm4 md3 lg1 xl1>
      <div class="caption grey--text text-center">SNR</div>
      <div class="text-center">{{packet.station.receptionParams.snr}} dB</div>
    </v-flex>
    <v-flex xs6 sm4 md2 lg2 xl1>
      <div class="caption grey--text text-center">Predicted Doppler</div>
      <div class="text-center">{{packet.station.doppler? packet.station.doppler.toFixed(2)+"Hz" : "- -"}}</div>
    </v-flex>
    <v-flex xs6 sm4 md4 lg2 xl1>
      <div class="caption grey--text text-center">Frequency Error</div>
      <div class="text-center">{{packet.station.receptionParams.frequency_error? packet.station.receptionParams.frequency_error.toFixed(2) +"Hz" : "- -"   }} </div>
    </v-flex>
    <v-flex xs6 sm4 md4 lg1 xl1>
      <div class="caption grey--text text-center">CRC Error</div>
      <div class="text-center"><v-icon color="red">{{packet.station.crc_error?"mdi-checkbox-blank-circle":""}}</v-icon></div>
    </v-flex>
    <v-flex xs6 sm4 md2 lg1 xl1>
      <div class="caption grey--text text-center">Received by</div>
      <div class="text-center">{{packet.stationNumber?packet.stationNumber + " Stations" : "- -" }}</div>
    </v-flex>
  </v-layout>
</v-card>
</template>

<script>
import moment from 'moment'

export default {
  components: {
  },
  name: "PacketRowGS",
  props: [
    "packet",
      ],
  methods: {
    dateFormat(time){
      return moment(time).format("ll kk:mm:ss")
    },
    dateSince(time) {
      return moment(time).fromNow()
    },
    getSatName(packet) {
      if (packet.raw.startsWith("VGlueUdTLXRlc3Q"))
        return "TinyGS Test Packet"
      
      if (!packet.parsed)
        return "Unknown"

      if ( typeof packet.satPos == 'undefined')
        return `${packet.satDisplayName } ${" ... 🤔 TLE ❌" }`

      return `${packet.satDisplayName} ${packet.sunLit?" ☀️":" 🌜"}`
    },
    testPacket(packet) {
       if (packet.raw.startsWith("VGlueUdTLXRlc3Q"))
        return true
    
    }
  }
}
</script>

<style>

</style>