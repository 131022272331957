import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Satellites from '../views/Satellites.vue'
import SatellitesIna from '../views/SatellitesIna.vue'
import SatellitesFut from '../views/SatellitesFut.vue'
import SatellitesSL from '../views/SatellitesSL.vue'
import SatellitesSB from '../views/SatellitesSB.vue'
import Balloons from '../views/Balloons.vue'
import Satellite from '../views/Satellite.vue'
import SatelliteIna from '../views/SatelliteIna.vue'
import Packet from '../views/Packet.vue'
import PacketIna from '../views/PacketIna.vue'
import Packets from '../views/Packets.vue'
import Station from '../views/Station.vue'
import User from '../views/User.vue'
import AprilFools from '../views/AprilFools.vue'
import About from '../views/About.vue'
import History from '../views/History.vue'
import Terms from '../views/Terms.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Home - TinyGS'
    }
  },
  {
    path: '/stations',
    name: 'Stations',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Stations.vue'),
    meta: {
      title: 'Stations - TinyGS'
    }
  },
  {
    path: '/satellites',
    name: 'Satellites',
    component: Satellites,
    meta: {
      title: 'Satellites - TinyGS'
    }
  },
  {
    path: '/satellitesSB',
    name: 'SatellitesSB',
    component: SatellitesSB,
    meta: {
      title: 'Satellites - TinyGS'
    }
  },
  {
    path: '/satellitesSL',
    name: 'SatellitesSL',
    component: SatellitesSL,
    meta: {
      title: 'Satellites - TinyGS'
    }
  },
  {
    path: '/satellitesIna',
    name: 'SatellitesIna',
    component: SatellitesIna,
    meta: {
      title: 'Satellites Ina- TinyGS'
    }
  },
  {
    path: '/satellitesFut',
    name: 'SatellitesFut',
    component: SatellitesFut,
    meta: {
      title: 'Satellites Fut- TinyGS'
    }
  },
  {
    path: '/balloons',
    name: 'Balloons',
    component: Balloons,
    meta: {
      title: 'Balloons - TinyGS'
    }
  },
  {
    path: '/satellite/:name',
    name: 'Satellite',
    component: Satellite,
    meta: {
      title: 'Satellite - TinyGS'
    }
  },
  {
    path: '/satelliteIna/:name',
    name: 'SatelliteIna',
    component: SatelliteIna,
    meta: {
      title: 'SatelliteIna - TinyGS'
    }
  },
  {
    path: '/packet/:id',
    name: 'Packet',
    component: Packet,
    meta: {
      title: 'Packet View - TinyGS'
    }
  },
  {
    path: '/packetIna/:id',
    name: 'PacketIna',
    component: PacketIna,
    meta: {
      title: 'Packet Ina View - TinyGS'
    }
  },
  {
    path: '/packets',
    name: 'Packets',
    component: Packets,
    meta: {
      title: 'Last received packets - TinyGS'
    }
  },
  {
    path: '/station/:id',
    name: 'Station',
    component: Station,
    meta: {
      title: 'Station Console - TinyGS'
    }
  },
  {
    path: '/user/:id',
    name: 'User',
    component: User,
    meta: {
      title: 'User Console - TinyGS'
    }
  },
  {
    path: '/april/:id',
    name: 'AprilFools',
    component: AprilFools,
    meta: {
      title: 'April Fools - TinyGS'
    }
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: {
      title: 'About - TinyGS'
    }
  },
  {
    path: '/history',
    name: 'History',
    component: History,
    meta: {
      title: 'History - TinyGS'
    }
  },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms,
    meta: {
      title: 'Terms and conditions - TinyGS'
    }
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, _, next) => {
  document.title = to.meta.title
  next()
})

export default router
