<template>
<div class="packet">
  <v-container>
    <h1 class="text-center display-4 grey--text text--darken-2 ma-5" color="">April Fools!</h1>
  </v-container>
</div>
</template>

<script>


export default {
  name: "AprilFools",
  components: {
  },
  data() {
    return {

    }
  },
}
</script>

<style>

</style>