<template>
  <v-container fluid class="ma-0 pa-0">
    <!--<iframe src="https://api.test.tinygs.com/worldmap/" style="height:60vh;width:100%;" ></iframe>-->

    <v-container class="grey--text text--darken-3">
      <!--

      <v-container class="grey--text text--darken-3" v-if="noUser">    
      <h2 class="grey--text text--darken-3 mb-4">Unleash Your Inner Explorer: Build a Tiny Ground Station and Help Secure Our Space Future</h2>
      <p>Imagine a world where anyone, anywhere can contribute to the advancement of space exploration.A world where a small, inexpensive device you build yourself help prevent a catastrophic event and connect you to the wonders of space exploration and the cutting edge of technology. </p>
      <p>This world is closer than you think.<b>TinyGS</b>, the Open Source Global Satellite Network, empowers you to become a space pioneer – right from your backyard. </p>
      <p>Space exploration is about more than just discovery. It's about pushing the boundaries of human knowledge and ensuring a brighter future for all. With the ever-increasing number of satellites orbiting Earth, the risk of collisions and debris fields – known as Kessler Syndrome – threatens to lock humanity out of space for generations. </p>
      <p>By building your own Tiny Ground Station, a tiny and affordable device powered by open-source technology. This network of citizen scientists, like yourself, acts as a collective space guardian, collecting vital data that helps precisely identify satellites, preventing costly mistakes and ensuring a safe and thriving space environment. </p>
      <p>TinyGS is more than just a network. It's a community passionate about space exploration and building a brighter future. Here, you'll gain access to: </p>
      <ul>
        <li><b>Easy-to-build</b> ground stations using readily available components.</li>
        <li><b>Open-source</b> knowledge and support to get you started. </li>
        <li>The opportunity to be part of a <b>global</b> effort that's shaping the future of space. </li>
      </ul>
      <p></p> <br>
      </v-container>
 
      <v-container class="grey--text text--darken-3" v-else>    
      <h2 class="grey--text text--darken-3 mb-4">Welcome Back, TinyGS Pioneers! Together, We're Shaping the Future of Space </h2>
      <p>The energy is electrifying! With each new Tiny Ground Station built, each byte of data collected, we're weaving a more robust safety net around our space environment. Thanks to your dedication, the dream of a thriving, accessible space is becoming a reality.</p>
      <p></p> 
      </v-container>
 
-->

      <v-layout row wrap class="justify-center py-6">
        <v-flex sm12 md4 pa-4>
          <v-layout justify-center>
            <v-card class="pa-2 ma-2 rounded-xl stats-card">
              <v-card-text>
                <div class="d-flex">
                  <v-avatar color="grey-lighten-2" size="72">
                    <v-img src="https://static.tinygs.com/team/gmartin.jpg" />
                  </v-avatar>

                  <div class="ps-6 font-weight-medium">
                    <div
                      class="text-h5 mb-1 font-weight-bold d-flex align-center"
                    >
                      <span class="me-3" v-text="german.name" />
                    </div>

                    <v-btn
                      v-for="link in links1"
                      :key="link.href"
                      :color="link.color"
                      text
                      rounded
                      :href="link.href"
                      target="_blank"
                    >
                      <v-icon class="mr-1">{{ link.icon }}</v-icon>
                      {{ link.name }}
                    </v-btn>
                  </div>
                </div>

                <div class="subheading font-weight-bold text-center"></div>
                <div class="d-flex flex-column align-items-start">
                  <div class="d-flex align-items-center">
                    <!-- New flex row for items on the same line -->
                    <h3 class="text-uppercase text-caption font-weight-regular">
                      FOCUS
                    </h3>
                    <div class="mx-0 my-0">
                      &nbsp;—&nbsp;Infrastructure & Backend
                    </div>
                  </div>

                  <div class="mx-2 my-1">
                    <v-icon class="mr-1">mdi-map-marker-outline</v-icon>
                    &nbsp;—&nbsp;Madrid, Spain
                  </div>
                  <div class="mx-2 my-1">
                    <v-icon class="mr-1">mdi-translate</v-icon>
                    &nbsp;—&nbsp; English • Spanish
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-layout>
        </v-flex>
        <v-flex sm12 md4 pa-4>
          <v-layout justify-center>
            <v-card class="pa-2 ma-2 rounded-xl stats-card">
              <v-card-text>
                <div class="d-flex">
                  <v-avatar color="grey-lighten-2" size="72">
                    <v-img src="https://static.tinygs.com/team/g4lile0.jpg" />
                  </v-avatar>

                  <div class="ps-6 font-weight-medium">
                    <div
                      class="text-h5 mb-1 font-weight-bold d-flex align-center"
                    >
                      <span class="me-3" v-text="g4lile0.name" />
                    </div>

                    <v-btn
                      v-for="link in links2"
                      :key="link.href"
                      :color="link.color"
                      text
                      rounded
                      :href="link.href"
                      target="_blank"
                    >
                      <v-icon class="mr-2">{{ link.icon }}</v-icon>
                      {{ link.name }}
                    </v-btn>
                  </div>
                </div>

                <div class="subheading font-weight-bold text-center"></div>
                <div class="d-flex flex-column align-items-start">
                  <div class="d-flex align-items-center">
                    <!-- New flex row for items on the same line -->
                    <h3 class="text-uppercase text-caption font-weight-regular">
                      FOCUS
                    </h3>
                    <div class="mx-0 my-0">
                      &nbsp;—&nbsp;FullStack & Firmware
                    </div>
                  </div>

                  <div class="mx-2 my-1">
                    <v-icon class="mr-1">mdi-map-marker-outline</v-icon>
                    &nbsp;—&nbsp;Madrid, Spain
                  </div>
                  <div class="mx-2 my-1">
                    <v-icon class="mr-1">mdi-translate</v-icon>
                    &nbsp;—&nbsp; English • Spanish • Italian
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-layout>
        </v-flex>

        <v-flex sm12 md4 pa-4>
          <v-layout justify-center>
            <v-card class="pa-2 ma-2 rounded-xl stats-card">
              <v-card-text>
                <div class="d-flex">
                  <v-avatar color="grey-lighten-2" size="72">
                    <v-img src="https://static.tinygs.com/team/dev_4m1g0.jpg" />
                  </v-avatar>

                  <div class="ps-6 font-weight-medium">
                    <div
                      class="text-h5 mb-1 font-weight-bold d-flex align-center"
                    >
                      <span class="me-3" v-text="dev_4m1g0.name" />
                    </div>

                    <v-btn
                      v-for="link in links3"
                      :key="link.href"
                      :color="link.color"
                      text
                      rounded
                      :href="link.href"
                      target="_blank"
                    >
                      <v-icon class="mr-1">{{ link.icon }}</v-icon>
                      {{ link.name }}
                    </v-btn>
                  </div>
                </div>

                <div class="subheading font-weight-bold text-center"></div>
                <div class="d-flex flex-column align-items-start">
                  <div class="d-flex align-items-center">
                    <!-- New flex row for items on the same line -->
                    <h3 class="text-uppercase text-caption font-weight-regular">
                      FOCUS
                    </h3>
                    <div class="mx-0 my-0">
                      &nbsp;—&nbsp;FullStack & Firmware
                    </div>
                  </div>

                  <div class="mx-2 my-1">
                    <v-icon class="mr-1">mdi-map-marker-outline</v-icon>
                    &nbsp;—&nbsp;Coruña, Spain
                  </div>
                  <div class="mx-2 my-1">
                    <v-icon class="mr-1">mdi-translate</v-icon>
                    &nbsp;—&nbsp; English • Spanish
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
    <p> &nbsp; </p><p> &nbsp; </p>
  </v-container>
</template>

<script>
const axios = require("axios");

export default {
  name: "Home",
  components: {},
  data() {
    return {
      noUser: false,
      german: { name: "German Martin" },
      g4lile0: { name: "G4lile0" },
      dev_4m1g0: { name: "dev.4m1g0" },
      activatorProps: true,
      linkas: [
        {
          twitter: {
            color: "#212121",
            href: `https://x.com/german`,
            icon: "$x",
            tooltip: "X",
          },
        },
        {
          color: "#24292E",
          href: `https://github.com/german`,
          icon: "mdi-github",
          tooltip: "GitHub",
        },
      ],
      links1: [
        {
          icon: "mdi-github",
          tooltip: "GitHub",
          href: "https://github.com/gmag11",
          color: "#24292E",
        },
        {
          icon: "mdi-mastodon",
          tooltip: "mastodon",
          href: "https://mastodon.social/@gmag11",
        },
        {
          icon: "$twitter",
          tooltip: "X",
          href: "https://x.com/gmag12",
          color: "#212121",
        },
        {
          icon: "mdi-linkedin",
          tooltip: "LinkedIn",
          href: "https://www.linkedin.com/in/martingerman",
          color: "#0077B5",
        },
      ],
      links2: [
        {
          icon: "mdi-github",
          tooltip: "GitHub",
          href: "https://github.com/G4lile0",
          color: "#24292E",
        },
        {
          icon: "mdi-mastodon",
          tooltip: "mastodon",
          href: "https://mastodon.social/@g4lile0",
        },
        {
          icon: "$twitter",
          tooltip: "X",
          href: "https://x.com/G4lile0",
          color: "#212121",
        },
        {
          icon: "mdi-linkedin",
          tooltip: "LinkedIn",
          href: "https://www.linkedin.com/in/jose-manuel-cuesta-05ba513",
          color: "#0077B5",
        },
      ],
      links3: [
        {
          icon: "mdi-github",
          tooltip: "GitHub",
          href: "https://github.com/4m1g0",
          color: "#24292E",
        },
        {
          icon: "$twitter",
          tooltip: "X",
          href: "https://x.com/dev_4m1g0",
          color: "#212121",
        },
        {
          icon: "mdi-linkedin",
          tooltip: "LinkedIn",
          href: "https://www.linkedin.com/in/o-blanco/",
          color: "#0077B5",
        },
      ],
      statistics: {},
    };
  },
  computed: {
    responsiveWidth() {
      if (window.innerWidth <= 768) {
        return 100; // Mobile width - 100%
      } else {
        return 60; // Desktop width
      }
    },
  },
  beforeMount() {
    this.getStatistics();
  },
  methods: {
    async getStatistics() {
      const { data } = await axios.get(
        `${process.env.VUE_APP_API_ENDPOINT}/v1/statistics`
      );
      console.log(data);
      this.statistics = data;
      if (!localStorage.userId) this.noUser = true;
    },
  },
};
</script>

<style scoped>
.home {
  height: 100%;
}

h2 {
  text-align: center;
}

.stats-card {
  min-width: 275px;
  margin: 0 auto;
}
</style>
