<template>

  <div>
    <l-map
      :zoom="zoom"
      :center="center"
      style="height: 500px; width: 100%; z-index:0;"
    >
      <l-control-layers ref="control"></l-control-layers>

      <l-tile-layer
        :url="url"
        :attribution="attribution"
      />

      <l-marker v-if="station" :lat-lng="station.location" :icon="stationActiveIcon"> 
         <l-popup :content="`<h3>${station.name}</h3>`" />
      </l-marker>

      <l-circle v-if="station"
        :lat-lng="station.location"
        :radius=1000000
        :weight=2
        :opacity=0.6
        :fillOpacity=0.1
      >
      </l-circle>

     <l-circle v-if="station"
        :lat-lng="station.location"
        :radius=2000000
        :weight=2
        :opacity=0.6
        :fillOpacity=0.1
      >
      </l-circle>

      <l-circle v-if="station"
        :lat-lng="station.location"
        :radius=3000000
        :weight=3
        :opacity=0.6
      >
      </l-circle>

     <l-layer-group v-if="filteredPacketsP1 && filteredPacketsP1.length > 0"
      layer-type="overlay"
      name=" < 100 mW"
      :visible="true">
      <l-marker v-for="packet in filteredPacketsP1" :key="packet.packetId" :lat-lng="[packet.satPos.lat, packet.satPos.lng]" :icon="satelliteIcon1">
           <l-popup><h3><router-link v-if=packet.packetId :to='`/packet/${packet.packetId}`'><v-icon>$satelliteDark</v-icon>{{packet.parsed?packet.satellite:"unknown"}}</router-link>
                        <div  v-else > <v-icon>$satelliteDark</v-icon> {{packet.parsed?packet.satellite:"unknown"}} </div></h3><br>
            <strong>⏱ Time:</strong> {{ (packet.serverTime) }}<br>
            <strong>📏 Distance:</strong> {{(packet.station.distance||0).toFixed(0)}} Km<br>
            <strong>📐 Elevation:</strong> {{packet.station.elevation? packet.station.elevation.toFixed(2) +"º" : "- -" }}<br>
            <strong>🧭 Azimuth:</strong> {{packet.station.azimuth? packet.station.azimuth.toFixed(2) +"º" : "- -" }}<br>
            <strong>📶 RSSI:</strong> {{packet.station.receptionParams.rssi}} dBm<br>
            <strong>📶 SNR:</strong> {{packet.station.receptionParams.snr}} dB<br>
            <strong>↕️ Freq error:</strong> {{(packet.station.receptionParams.frequency_error).toFixed(0)}} Hz<br>
            </l-popup>             

      </l-marker>
    </l-layer-group>

     <l-layer-group v-if="filteredPacketsP2 && filteredPacketsP2.length > 0"
      layer-type="overlay"
      name=" 101 to 500 mW"
      :visible="true">
      <l-marker v-for="packet in filteredPacketsP2" :key="packet.packetId" :lat-lng="[packet.satPos.lat, packet.satPos.lng]" :icon="satelliteIcon2">
           <l-popup><h3><router-link v-if=packet.packetId :to='`/packet/${packet.packetId}`'><v-icon>$satelliteDark</v-icon>{{packet.parsed?packet.satellite:"unknown"}}</router-link>
                        <div  v-else > <v-icon>$satelliteDark</v-icon> {{packet.parsed?packet.satellite:"unknown"}} </div></h3><br>
            <strong>📏 Distance:</strong> {{(packet.station.distance||0).toFixed(0)}} Km<br>
            <strong>📐 Elevation:</strong> {{packet.station.elevation? packet.station.elevation.toFixed(2) +"º" : "- -" }}<br>
            <strong>🧭 Azimuth:</strong> {{packet.station.azimuth? packet.station.azimuth.toFixed(2) +"º" : "- -" }}<br>
            <strong>📶 RSSI:</strong> {{packet.station.receptionParams.rssi}} dBm<br>
            <strong>📶 SNR:</strong> {{packet.station.receptionParams.snr}} dB<br>
            <strong>↕️ Freq error:</strong> {{(packet.station.receptionParams.frequency_error).toFixed(0)}} Hz<br>
            </l-popup>             

      </l-marker>
    </l-layer-group>

     <l-layer-group  v-if="filteredPacketsP3 && filteredPacketsP3.length > 0"
      layer-type="overlay"
      name=" 501 to 1000 mW"
      :visible="true">
      <l-marker v-for="packet in filteredPacketsP3" :key="packet.packetId" :lat-lng="[packet.satPos.lat, packet.satPos.lng]" :icon="satelliteIcon3">
           <l-popup><h3><router-link v-if=packet.packetId :to='`/packet/${packet.packetId}`'><v-icon>$satelliteDark</v-icon>{{packet.parsed?packet.satellite:"unknown"}}</router-link>
                        <div  v-else > <v-icon>$satelliteDark</v-icon> {{packet.parsed?packet.satellite:"unknown"}} </div></h3><br>
            <strong>📏 Distance:</strong> {{(packet.station.distance||0).toFixed(0)}} Km<br>
            <strong>📐 Elevation:</strong> {{packet.station.elevation? packet.station.elevation.toFixed(2) +"º" : "- -" }}<br>
            <strong>🧭 Azimuth:</strong> {{packet.station.azimuth? packet.station.azimuth.toFixed(2) +"º" : "- -" }}<br>
            <strong>📶 RSSI:</strong> {{packet.station.receptionParams.rssi}} dBm<br>
            <strong>📶 SNR:</strong> {{packet.station.receptionParams.snr}} dB<br>
            <strong>↕️ Freq error:</strong> {{(packet.station.receptionParams.frequency_error).toFixed(0)}} Hz<br>
            </l-popup>             

      </l-marker>
    </l-layer-group>

     <l-layer-group  v-if="filteredPacketsP4 && filteredPacketsP4.length > 0"
      layer-type="overlay"  
      name="1001 to 2000 mW"
      :visible="true">
      <l-marker v-for="packet in filteredPacketsP4" :key="packet.packetId" :lat-lng="[packet.satPos.lat, packet.satPos.lng]" :icon="satelliteIcon4">
           <l-popup><h3><router-link v-if=packet.packetId :to='`/packet/${packet.packetId}`'><v-icon>$satelliteDark</v-icon>{{packet.parsed?packet.satellite:"unknown"}}</router-link>
                        <div  v-else > <v-icon>$satelliteDark</v-icon> {{packet.parsed?packet.satellite:"unknown"}} </div></h3><br>
            <strong>⏱ Time:</strong>{{dateFormat(packet.serverTime) }}<br>
            <strong>📏 Distance:</strong> {{(packet.station.distance||0).toFixed(0)}} Km<br>
            <strong>📐 Elevation:</strong> {{packet.station.elevation? packet.station.elevation.toFixed(2) +"º" : "- -" }}<br>
            <strong>🧭 Azimuth:</strong> {{packet.station.azimuth? packet.station.azimuth.toFixed(2) +"º" : "- -" }}<br>
            <strong>📶 RSSI:</strong> {{packet.station.receptionParams.rssi}} dBm<br>
            <strong>📶 SNR:</strong> {{packet.station.receptionParams.snr}} dB<br>
            <strong>↕️ Freq error:</strong> {{(packet.station.receptionParams.frequency_error).toFixed(0)}} Hz<br>
            </l-popup>             

      </l-marker>
    </l-layer-group>

     <l-layer-group  v-if="filteredPacketsP5 && filteredPacketsP5.length > 0"
      layer-type="overlay"
      name="> 2000 mW"
      :visible="true">
      <l-marker v-for="packet in filteredPacketsP5" :key="packet.packetId" :lat-lng="[packet.satPos.lat, packet.satPos.lng]" :icon="satelliteIcon5">
           <l-popup><h3><router-link v-if=packet.packetId :to='`/packet/${packet.packetId}`'><v-icon>$satelliteDark</v-icon>{{packet.parsed?packet.satellite:"unknown"}}</router-link>
                        <div  v-else > <v-icon>$satelliteDark</v-icon> {{packet.parsed?packet.satellite:"unknown"}} </div></h3><br>
            <strong>⏱ Tim0:</strong> {{ (packet.serverTime) }}<br>
            <strong>📏 Distance:</strong> {{(packet.station.distance||0).toFixed(0)}} Km<br>
            <strong>📐 Elevation:</strong> {{packet.station.elevation? packet.station.elevation.toFixed(2) +"º" : "- -" }}<br>
            <strong>🧭 Azimuth:</strong> {{packet.station.azimuth? packet.station.azimuth.toFixed(2) +"º" : "- -" }}<br>
            <strong>📶 RSSI:</strong> {{packet.station.receptionParams.rssi}} dBm<br>
            <strong>📶 SNR:</strong> {{packet.station.receptionParams.snr}} dB<br>
            <strong>↕️ Freq error:</strong> {{(packet.station.receptionParams.frequency_error).toFixed(0)}} Hz<br>
            </l-popup>             

      </l-marker>
    </l-layer-group>


      <l-layer-group  v-if="filteredPacketsCRCerror && filteredPacketsCRCerror.length > 0"
      layer-type="overlay"
      name="CRC ERROR"
      :visible="true"
    >


          <l-marker v-for="packet in filteredPacketsCRCerror" :key="packet.packetId" :lat-lng="[packet.satPos.lat, packet.satPos.lng]" :icon="satelliteIconCRC">
          <l-popup><h3><router-link v-if=packet.packetId :to='`/packet/${packet.packetId}`'><v-icon>$satelliteDark</v-icon>{{packet.parsed?packet.satellite:"unknown"}}</router-link>
                        <div  v-else > {{packet.parsed?packet.satellite:"unknown"}} </div></h3><br>
            <strong>📏 Distance:</strong> {{(packet.station.distance||0).toFixed(0)}} Km<br>
            <strong>📐 Elevation:</strong> {{packet.station.elevation? packet.station.elevation.toFixed(2) +"º" : "- -" }}<br>
            <strong>🧭 Azimuth:</strong> {{packet.station.azimuth? packet.station.azimuth.toFixed(2) +"º" : "- -" }}<br>
            <strong>📶 RSSI:</strong> {{packet.station.receptionParams.rssi}} dBm<br>
            <strong>📶 SNR:</strong> {{packet.station.receptionParams.snr}} dB<br>
            <strong>↕️ Freq error:</strong> {{(packet.station.receptionParams.frequency_error).toFixed(0)}} Hz<br>
            </l-popup>             
      </l-marker>

    </l-layer-group>

    
     <l-layer-group v-if="filteredPacketsNT && filteredPacketsNT.length > 0"
      layer-type="overlay"
      name="No Telemetry"
      :visible="true">
      <l-marker v-for="packet in filteredPacketsNT" :key="packet.packetId" :lat-lng="[packet.satPos.lat, packet.satPos.lng]" :icon="satelliteIconNT">
           <l-popup><h3><router-link v-if=packet.packetId :to='`/packet/${packet.packetId}`'><v-icon>$satelliteDark</v-icon>{{packet.parsed?packet.satellite:"unknown"}}</router-link>
                        <div  v-else > <v-icon>$satelliteDark</v-icon> {{packet.parsed?packet.satellite:"unknown"}} </div></h3><br>
            <strong>📏 Distance:</strong> {{(packet.station.distance||0).toFixed(0)}} Km<br>
            <strong>📐 Elevation:</strong> {{packet.station.elevation? packet.station.elevation.toFixed(2) +"º" : "- -" }}<br>
            <strong>🧭 Azimuth:</strong> {{packet.station.azimuth? packet.station.azimuth.toFixed(2) +"º" : "- -" }}<br>
            <strong>📶 RSSI:</strong> {{packet.station.receptionParams.rssi}} dBm<br>
            <strong>📶 SNR:</strong> {{packet.station.receptionParams.snr}} dB<br>
            <strong>↕️ Freq error:</strong> {{(packet.station.receptionParams.frequency_error).toFixed(0)}} Hz<br>
            </l-popup>             

      </l-marker>
    </l-layer-group>

    <l-layer-group v-if="statisticsPoints && statisticsPoints.length > 0"
      layer-type="overlay"
      name="Heat Map"
      :visible="true">
      <l-circle v-for="(packet, i) in statisticsPoints" :key="i" :lat-lng="[packet.satPos.lat, packet.satPos.lng]" :radius=300000 :weight=0 :opacity=0.6 :fillOpacity=0.05 fillColor="yellow"></l-circle>
    </l-layer-group>

    <l-layer-group 
      layer-type="overlay"
      name="Radiation Diagram"
      :visible="true"
      >
      <l-circle v-for="(circle, i) in [500000, 1000000, 1500000, 2000000, 2500000, 3000000]" :key="i" :lat-lng="station.location" :radius="circle" :weight=1 :opacity=0.3 :fillOpacity=0 color="black"></l-circle>

      <l-polyline v-for="angle in getTestPoints()" :key="'A' + angle" :lat-lngs="[station.location, getCircunferencePoint(station.location, angle)]" :opacity=0.3 :weight=1 color="black" />
    </l-layer-group>
    

    
     <l-control-scale position="bottomleft" :imperial="true" :metric="true"></l-control-scale>
    </l-map>
  </div>
</template>

<script>
import moment from 'moment'
import {
  LMap,
  LTileLayer,
  LCircle,
  LPopup,
  LMarker,
  LControlScale,
  LControlLayers,
  LLayerGroup,
  LPolyline,
} from "vue2-leaflet";
import { icon } from "leaflet";  
import L from 'leaflet';

export default {
  name: "PacketMapGS",
  components: {
    LMap,
    LTileLayer,
    LCircle,
    LPopup,
    LMarker,
    LControlScale,
    LControlLayers,
    LLayerGroup,
    LPolyline
  },
  props: [
      "packets",
      "station",
      "statistics"
  ],
  data() {
    return {
      satelliteIconNT: icon({
                        iconUrl: `${process.env.VUE_APP_STATIC_HOST}/sat_recNT.png`,
                        iconSize:     [16, 16], // size of the icon
                        iconAnchor:   [8, 8], // point of the icon which will correspond to marker's location
                    }),
      satelliteIcon1: icon({
                        iconUrl: `${process.env.VUE_APP_STATIC_HOST}/sat_rec1.png`,
                        iconSize:     [16, 16], // size of the icon
                        iconAnchor:   [8, 8], // point of the icon which will correspond to marker's location
                    }),
      satelliteIcon2: icon({
                        iconUrl: `${process.env.VUE_APP_STATIC_HOST}/sat_rec2.png`,
                        iconSize:     [16, 16], // size of the icon
                        iconAnchor:   [8, 8], // point of the icon which will correspond to marker's location
                    }),
      satelliteIcon3: icon({
                        iconUrl: `${process.env.VUE_APP_STATIC_HOST}/sat_rec3.png`,
                        iconSize:     [16, 16], // size of the icon
                        iconAnchor:   [8, 8], // point of the icon which will correspond to marker's location
                    }),
      satelliteIcon4: icon({
                        iconUrl: `${process.env.VUE_APP_STATIC_HOST}/sat_rec4.png`,
                        iconSize:     [16, 16], // size of the icon
                        iconAnchor:   [8, 8], // point of the icon which will correspond to marker's location
                    }),
      satelliteIcon5: icon({
                        iconUrl: `${process.env.VUE_APP_STATIC_HOST}/sat_rec5.png`,
                        iconSize:     [16, 16], // size of the icon
                        iconAnchor:   [8, 8], // point of the icon which will correspond to marker's location
                    }),
      satelliteIconCRC: icon({
                        iconUrl: `${process.env.VUE_APP_STATIC_HOST}/sat_crc5.png`,
                        iconSize:     [16, 16], // size of the icon
                        iconAnchor:   [8, 8], // point of the icon which will correspond to marker's location
                    }),
      stationActiveIcon: icon({
                        iconUrl: `${process.env.VUE_APP_STATIC_HOST}/station_icon_green.png`,
                        iconSize:     [32, 32], // size of the icon
                        iconAnchor:   [16, 16], // point of the icon which will correspond to marker's location
                    }),
      stationErrorIcon: icon({
                        iconUrl: `${process.env.VUE_APP_STATIC_HOST}/station_icon_orange.png`,
                        iconSize:     [32, 32], // size of the icon
                        iconAnchor:   [16, 16], // point of the icon which will correspond to marker's location
                    }),
      zoom: 3,
      center: this.station?this.station.location:[0,0],
      circle: {
        center: this.station?this.station.location:[0,0],
        radius: 2500
      },
      layersPosition: 'topright',
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    };
  },
  methods:{
    // Credit to Leaflet.GeometryUtil: http://makinacorpus.github.io/Leaflet.GeometryUtil/index.html
    getCircunferencePoint(center, angle) {
      let destination= function(latlng, heading, distance) {
        let a = L.latLng(latlng[0], latlng[1]);
        let b = L.latLng(90, 0);
        if (a.distanceTo(b) < 3100000) {
          return latlng // return the same point for stations too on the north
        }

        heading = (heading + 360) % 360;
        var rad = Math.PI / 180,
            radInv = 180 / Math.PI,
            R = 6378137, // approximation of Earth's radius
            lon1 = latlng[1] * rad,
            lat1 = latlng[0] * rad,
            rheading = heading * rad,
            sinLat1 = Math.sin(lat1),
            cosLat1 = Math.cos(lat1),
            cosDistR = Math.cos(distance / R),
            sinDistR = Math.sin(distance / R),
            lat2 = Math.asin(sinLat1 * cosDistR + cosLat1 *
                sinDistR * Math.cos(rheading)),
            lon2 = lon1 + Math.atan2(Math.sin(rheading) * sinDistR *
                cosLat1, cosDistR - sinLat1 * Math.sin(lat2));
        lon2 = lon2 * radInv;
        lon2 = lon2 > 180 ? lon2 - 360 : lon2 < -180 ? lon2 + 360 : lon2;
        if (lon2 - latlng[1] < 100) { // hack to avoid longitude jumps
          lon2 += 360
        }
        if (lon2 - latlng[1] > 100) {
          lon2 -= 360
        }
        
        return [lat2 * radInv, lon2];
      }

      return destination(center, angle, 3000000)
    },
    getTestPoints(){
      let list = []
      for (let i = 0; i < 360; i+=22.5){
        list.push(i)
      }
      
      return list
    },
    dateFormat(time){
      return moment(time).format("lll")
    }, 
  },
  computed: { 
    filteredPacketsP1() {
      if (!this.packets) return null
      return this.packets.filter(p => typeof p.satPos !== 'undefined'  && !p.raw.startsWith("RXJyb3JfQ1JD") && (p.txPower<101) )
    },    
    filteredPacketsP2() {
      if (!this.packets) return null
      return this.packets.filter(p => typeof p.satPos !== 'undefined' && !p.raw.startsWith("RXJyb3JfQ1JD") && (p.txPower>100) && (p.txPower<501) )
    },    
    filteredPacketsP3() {
      if (!this.packets) return null
      return this.packets.filter(p => typeof p.satPos !== 'undefined' && !p.raw.startsWith("RXJyb3JfQ1JD") && (p.txPower>500) && (p.txPower<1001) )
    },    
    filteredPacketsP4() {
      if (!this.packets) return null
      return this.packets.filter(p => typeof p.satPos !== 'undefined'  && !p.raw.startsWith("RXJyb3JfQ1JD") && (p.txPower>1000) && (p.txPower<2001) )
    },
    filteredPacketsP5() {
      if (!this.packets) return null
      return this.packets.filter(p => typeof p.satPos !== 'undefined'  && !p.raw.startsWith("RXJyb3JfQ1JD") && (p.txPower>2001))
    },    
    filteredPacketsNT() {
      if (!this.packets) return null
      return this.packets.filter(p => typeof p.satPos !== 'undefined'  && !p.raw.startsWith("RXJyb3JfQ1JD") && !p.txPower)
    },    
    filteredPacketsCRCerror() {
      if (!this.packets) return null
      return this.packets.filter(p => typeof p.satPos !== 'undefined'  && p.raw.startsWith("RXJyb3JfQ1JD"))
    },
    statisticsPoints() {
      if (!this.statistics) return null;
      let statisticsPoints = [];
      for (const day of this.statistics) {
        if (day.frames) {
          statisticsPoints.push(...day.frames);
        }
      }
      return statisticsPoints
      .filter(p => p.satPos && p.satPos.lat != null && p.satPos.lng != null     )
      .slice(0, 2000);
    },

  }
};
</script>
