import Vue from 'vue';
import Vuetify, { VLayout, VFlex } from 'vuetify/lib';
import SatelliteIcon from '@/Icons/SatelliteIcon.vue'
import BalloonIcon from '@/Icons/BalloonIcon.vue'
import StationIcon from '@/Icons/StationIcon.vue'
import SatelliteIconDark from '@/Icons/SatelliteIconDark.vue'
import TwitterIcon from '@/Icons/TwitterIcon.vue'
import RotateOrbit from '@/Icons/RotateOrbit.vue'

Vue.use(Vuetify, {
    components: { VLayout, VFlex },
});

export default new Vuetify({
    icons: {
        values: {
            satellite: {
                component: SatelliteIcon
            },
            balloon: {
                component: BalloonIcon
            },
            satelliteDark: {
                component: SatelliteIconDark
            },
            station: {
                component: StationIcon
            },
            twitter: {
                component: TwitterIcon
            },
            rotateOrbit: {
                component: RotateOrbit
            }

        }
    }
});
