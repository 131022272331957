<template>
  <v-container fluid class="ma-0 pa-0">
      <!--<iframe src="https://api.test.tinygs.com/worldmap/" style="height:60vh;width:100%;" ></iframe>-->
    <v-container class="grey--text text--darken-3" >

      <v-container class="grey--text text--darken-3" v-if="noUser">    

        <h1>Terms of Use and Conditions</h1>
        <p>
            TinyGS promotes the safety of space flights, the protection of the space environment, and the peaceful use of space globally by sharing space situational awareness information and telemetry with satellite owners/operators, academic institutions, and other entities. Please ensure you understand the following terms of use and conditions:
        </p>

        <h2>General Information</h2>
        <p>
            This disclaimer (hereinafter, "Disclaimer") governs the use of the TinyGS web service (hereinafter, "the Service"), which is operated by [Name of the Operator] (hereinafter, "the Operator") and hosted in the European Union. By accessing and using the Service, the user (hereinafter, "the User") agrees to this Disclaimer in its entirety. If the User does not agree with any part of this Disclaimer, they must refrain from using the Service.
        </p>

        <h2>Description of the Service</h2>
        <p>
            TinyGS is a community that enables the reception of open satellite signals. Signals are received with stations built by the users themselves. The stations connect to a central server that collects the data and compares it with those received by other users to consolidate them, as the same transmission is usually received by multiple stations. The received data is displayed on the website, associated with the stations that received them.
        </p>

        <h2>Personal Data</h2>

        <h3>Collected Information</h3>
        <ul>
            <li><strong>Name and description of the station:</strong> It does not need to match any real data. Users can use pseudonyms or amateur radio call signs. The choice of a name is the sole responsibility of the user.</li>
            <li><strong>Location of the station:</strong> It is necessary to coordinate the reception of satellites when they are over the user's horizon. The accuracy of this data is limited by the software of the stations.</li>
        </ul>

        <h3>Images</h3>
        <p>
            Users can provide images of their installations or related to them, which will be publicly displayed on the website. The images are moderated to avoid publishing content that infringes on essential rights of individuals or properties, as well as violent or adult content. However, the content of the images is the sole responsibility of each user. The community disclaims any liability in this regard.
        </p>

        <h3>Data Deletion</h3>
        <p>
            No more data associated with the users is stored. Users can request the deletion of references to their station on the platform. In such cases, the data will be anonymized but not deleted, as they are not owned by the receiving stations.
        </p>

        <h2>Data Collection and Transmission</h2>
        <p>
            Any user can request to participate in the community. If they have a compatible station, they can follow the steps described on the website. The installation of the station and the transmission of data to the server are voluntary and without compensation. Users can stop sending data at any time.
        </p>
        <p>
            Data transmission must be done in real-time. Sending delayed, modified, manipulated, or fabricated data is prohibited unless expressly agreed in writing, as this would provide a misleading and deceptive image of the received data.
        </p>
        <p>
            Users providing data grant a royalty-free, worldwide, perpetual, irrevocable, and non-exclusive license to use, reproduce, modify, adapt, publish, display, translate, and distribute the data, in whole or in part, and to incorporate it into other works in any form, medium, or technology now known or hereafter developed.
        </p>

        <h2>User Chat</h2>
        <p>
            TinyGS has a discussion group (currently on Telegram) where users can post photos, debate, and comment. By posting material in the chat, the user agrees that such material and their registered username will be available to the public and other users.
        </p>
        <p>
            The material must not:
        </p>
        <ul>
            <li>Contain content protected by copyright, trademark laws, or other intellectual property rights without the express consent of the rights holder.</li>
            <li>Market any product, service, or business.</li>
            <li>Identify any person without their express consent.</li>
            <li>Be offensive, disturbing, harassing, obscene, pornographic, discriminatory, or inappropriate.</li>
        </ul>
        <p>
            The user is responsible for any damage suffered by TinyGS as a result of material posted in violation of these terms. TinyGS reserves the right to review and remove any material at its discretion.
        </p>

        <h2>No Warranties</h2>
        <p>
            The services and their content are provided "as is" and "as available." TinyGS makes no warranties, express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, or non-infringement of intellectual property. We do not guarantee the accuracy, probable results, or reliability of the content accessible through the Service.
        </p>
        <p>
            We do not guarantee that the Service will be uninterrupted or error-free, or that the servers will be free of viruses or other harmful components, although we take steps to ensure this. The Service may contain technical, typographical, or photographic errors. TinyGS does not warrant that the materials are accurate, complete, or current.
        </p>

        <h2>Disclaimer of Liability</h2>
        <p>
            Unless caused by gross negligence or willful misconduct, TinyGS will not be liable for any loss or damage, direct or indirect, arising from the use or inability to use the content of the Service.
        </p>
        <p>
            TinyGS cannot guarantee the security of information transmitted through the Service and assumes no responsibility for the information contained therein. The Service may contain links to third-party websites that are not under our control, and we are not responsible for the content of such sites.
        </p>

        <h2>Changes to the Service</h2>
        <p>
            TinyGS reserves the right to add, delete, modify, or change the format and features of the Service at any time. It may also be required to alter the Service due to mandatory laws or government decisions.
        </p>

        <h2>Governing Law</h2>
        <p>
            The use of the Service is governed by the laws of the European Union and the Kingdom of Spain. Any disputes will be subject to the exclusive jurisdiction of the courts of Madrid, Spain.
        </p>

        <h2>Security and Regulations</h2>
        <p>
            The devices related to this project are not manufactured by TinyGS and have not been tested by regulatory organizations. Their use should be considered experimental, and users should take precautions to avoid risks. The user is responsible for complying with the applicable local laws in their country or region.
        </p>

        <h2>Software Licenses</h2>
        <p>
            The station software is licensed under the GPLv3 license.
        </p>

        <h2>Disclaimer of Warranty</h2>
        <p>
            There is no warranty for the program, except when stated otherwise in writing. The copyright holders provide the program "as is" without any warranties of any kind. The entire risk as to the quality and performance of the program is with the user.
        </p>

        <h2>Limitation of Liability</h2>
        <p>
            In no event, unless required by applicable law, shall any copyright holder be liable for damages arising from the use or inability to use the program.
        </p>

        <h2>Interpretation</h2>
        <p>
            If the disclaimer of warranty and the limitation of liability have no legal effect, the courts will apply the local law that most closely resembles an absolute waiver of civil liability concerning the program.
        </p>

        <h2>Contact</h2>
        <p>
            For any queries related to this Disclaimer, users can contact TinyGS via the following email: <a href="mailto:terms@tinygs.com">terms@tinygs.com</a>
        </p>
        <p>
            TinyGS reserves the right to change or modify these terms and conditions at any time and without prior notice.
        </p>
        <p> &nbsp; </p><p> &nbsp; </p>
        <p> &nbsp; </p><p> &nbsp; </p>

      </v-container>
 
      <v-container class="grey--text text--darken-3" v-else>    

        <h1>Terms of Use and Conditions</h1>
        <p>
            TinyGS promotes the safety of space flights, the protection of the space environment, and the peaceful use of space globally by sharing space situational awareness information and telemetry with satellite owners/operators, academic institutions, and other entities. Please ensure you understand the following terms of use and conditions:
        </p>

        <h2>General Information</h2>
        <p>
            This disclaimer (hereinafter, "Disclaimer") governs the use of the TinyGS web service (hereinafter, "the Service"), which is operated by [Name of the Operator] (hereinafter, "the Operator") and hosted in the European Union. By accessing and using the Service, the user (hereinafter, "the User") agrees to this Disclaimer in its entirety. If the User does not agree with any part of this Disclaimer, they must refrain from using the Service.
        </p>

        <h2>Description of the Service</h2>
        <p>
            TinyGS is a community that enables the reception of open satellite signals. Signals are received with stations built by the users themselves. The stations connect to a central server that collects the data and compares it with those received by other users to consolidate them, as the same transmission is usually received by multiple stations. The received data is displayed on the website, associated with the stations that received them.
        </p>

        <h2>Personal Data</h2>

        <h3>Collected Information</h3>
        <ul>
            <li><strong>Name and description of the station:</strong> It does not need to match any real data. Users can use pseudonyms or amateur radio call signs. The choice of a name is the sole responsibility of the user.</li>
            <li><strong>Location of the station:</strong> It is necessary to coordinate the reception of satellites when they are over the user's horizon. The accuracy of this data is limited by the software of the stations.</li>
        </ul>

        <h3>Images</h3>
        <p>
            Users can provide images of their installations or related to them, which will be publicly displayed on the website. The images are moderated to avoid publishing content that infringes on essential rights of individuals or properties, as well as violent or adult content. However, the content of the images is the sole responsibility of each user. The community disclaims any liability in this regard.
        </p>

        <h3>Data Deletion</h3>
        <p>
            No more data associated with the users is stored. Users can request the deletion of references to their station on the platform. In such cases, the data will be anonymized but not deleted, as they are not owned by the receiving stations.
        </p>

        <h2>Data Collection and Transmission</h2>
        <p>
            Any user can request to participate in the community. If they have a compatible station, they can follow the steps described on the website. The installation of the station and the transmission of data to the server are voluntary and without compensation. Users can stop sending data at any time.
        </p>
        <p>
            Data transmission must be done in real-time. Sending delayed, modified, manipulated, or fabricated data is prohibited unless expressly agreed in writing, as this would provide a misleading and deceptive image of the received data.
        </p>
        <p>
            Users providing data grant a royalty-free, worldwide, perpetual, irrevocable, and non-exclusive license to use, reproduce, modify, adapt, publish, display, translate, and distribute the data, in whole or in part, and to incorporate it into other works in any form, medium, or technology now known or hereafter developed.
        </p>

        <h2>User Chat</h2>
        <p>
            TinyGS has a discussion group (currently on Telegram) where users can post photos, debate, and comment. By posting material in the chat, the user agrees that such material and their registered username will be available to the public and other users.
        </p>
        <p>
            The material must not:
        </p>
        <ul>
            <li>Contain content protected by copyright, trademark laws, or other intellectual property rights without the express consent of the rights holder.</li>
            <li>Market any product, service, or business.</li>
            <li>Identify any person without their express consent.</li>
            <li>Be offensive, disturbing, harassing, obscene, pornographic, discriminatory, or inappropriate.</li>
        </ul>
        <p>
            The user is responsible for any damage suffered by TinyGS as a result of material posted in violation of these terms. TinyGS reserves the right to review and remove any material at its discretion.
        </p>

        <h2>No Warranties</h2>
        <p>
            The services and their content are provided "as is" and "as available." TinyGS makes no warranties, express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, or non-infringement of intellectual property. We do not guarantee the accuracy, probable results, or reliability of the content accessible through the Service.
        </p>
        <p>
            We do not guarantee that the Service will be uninterrupted or error-free, or that the servers will be free of viruses or other harmful components, although we take steps to ensure this. The Service may contain technical, typographical, or photographic errors. TinyGS does not warrant that the materials are accurate, complete, or current.
        </p>

        <h2>Disclaimer of Liability</h2>
        <p>
            Unless caused by gross negligence or willful misconduct, TinyGS will not be liable for any loss or damage, direct or indirect, arising from the use or inability to use the content of the Service.
        </p>
        <p>
            TinyGS cannot guarantee the security of information transmitted through the Service and assumes no responsibility for the information contained therein. The Service may contain links to third-party websites that are not under our control, and we are not responsible for the content of such sites.
        </p>

        <h2>Changes to the Service</h2>
        <p>
            TinyGS reserves the right to add, delete, modify, or change the format and features of the Service at any time. It may also be required to alter the Service due to mandatory laws or government decisions.
        </p>

        <h2>Governing Law</h2>
        <p>
            The use of the Service is governed by the laws of the European Union and the Kingdom of Spain. Any disputes will be subject to the exclusive jurisdiction of the courts of Madrid, Spain.
        </p>

        <h2>Security and Regulations</h2>
        <p>
            The devices related to this project are not manufactured by TinyGS and have not been tested by regulatory organizations. Their use should be considered experimental, and users should take precautions to avoid risks. The user is responsible for complying with the applicable local laws in their country or region.
        </p>

        <h2>Software Licenses</h2>
        <p>
            The station software is licensed under the GPLv3 license.
        </p>

        <h2>Disclaimer of Warranty</h2>
        <p>
            There is no warranty for the program, except when stated otherwise in writing. The copyright holders provide the program "as is" without any warranties of any kind. The entire risk as to the quality and performance of the program is with the user.
        </p>

        <h2>Limitation of Liability</h2>
        <p>
            In no event, unless required by applicable law, shall any copyright holder be liable for damages arising from the use or inability to use the program.
        </p>

        <h2>Interpretation</h2>
        <p>
            If the disclaimer of warranty and the limitation of liability have no legal effect, the courts will apply the local law that most closely resembles an absolute waiver of civil liability concerning the program.
        </p>

        <h2>Contact</h2>
        <p>
            For any queries related to this Disclaimer, users can contact TinyGS via the following email: <a href="mailto:terms@tinygs.com">terms@tinygs.com</a>
        </p>
        <p>
            TinyGS reserves the right to change or modify these terms and conditions at any time and without prior notice.
        </p>
        <p> &nbsp; </p><p> &nbsp; </p>
        <p> &nbsp; </p><p> &nbsp; </p>
      </v-container>
 

    </v-container>
      
  </v-container>

</template>

<script>

const axios = require("axios");

export default {
  name: 'Home',
  components: {
    
  
  },
  data() {
    return {
      noUser : false,
      links: [
        {icon:"mdi-github", name:' GitHub',url:"https://github.com/G4lile0/tinyGS"},
        {icon:"mdi-file-document-multiple-outline", name:' Wiki',url:"https://github.com/G4lile0/tinyGS/wiki"},
        {icon:"mdi-map-marker-question-outline", name:' FAQ',url:"https://github.com/G4lile0/tinyGS/wiki/FAQ"},
        {icon:"mdi-forum", name:' Telegram',url:"https://t.me/joinchat/DmYSElZahiJGwHX6jCzB3Q"},
      ],
      statistics: {}
    }
  },
  computed: {
  responsiveWidth() {
    if (window.innerWidth <= 768) {
      return 100; // Mobile width - 100%
    } else {
      return 60; // Desktop width
    }
  },
},
  beforeMount(){
    this.getStatistics()
  },
  methods: {
    async getStatistics() {
      const { data } = await axios.get(`${process.env.VUE_APP_API_ENDPOINT}/v1/statistics`);
      console.log(data);
      this.statistics = data;
      if (!localStorage.userId) this.noUser = true;
    }
  }

}
</script>

<style scoped>
.home {
  height: 100%;
}

.container {
            padding: 20px;
            max-width: 1000px;
            margin: auto;
        }
        
h1, h2 {
            color: #333;
        }

.stats-card {
  min-width: 275px;
  margin:0 auto;
}

ul {
            list-style-type: disc;
            margin-left: 20px;
        }
p {
    margin: 10px 0;
}

</style>
