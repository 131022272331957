<template>
  <v-dialog v-model="modal" max-width="1010px" transition="dialog-top-transition">
    <template v-slot:activator="{ on:modal }">
      
      <v-tooltip top :disabled="station.status != 0">
        <template v-slot:activator="{ on:tooltip }">
          <span v-on="tooltip">
            <v-btn class="mr-2" slot="activator" :disabled="station.status == 0" v-on="modal" color="success">
              <v-icon left>mdi-monitor-edit</v-icon>
              <span>Operate</span>
            </v-btn>
          </span>
        </template>
        <span>Your station has to be online to be operated</span>
      </v-tooltip>
    </template>
    
    <v-card>
      <v-card-title><h3><v-icon left>mdi-monitor-edit</v-icon> Operation</h3><v-spacer></v-spacer><v-btn icon @click="modal=false"><v-icon>mdi-close</v-icon></v-btn></v-card-title>
      <v-card-text>
        <v-card flat>
          <v-card-title>Automatic Tuning <HelpTooltip customClass="ml-2" text="If you set auto tune, your station will atutomatically change the rx parameters when the next satellite is overhead. There will be no change until a satellite is in range." /></v-card-title>
          <v-card-text>
            <v-form>
              <v-row justify="center">
                <v-select v-model="autoTune" :items="autoTunes" outlined label="Auto Tune" style="max-width:400px" class="mt-4"></v-select> 
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
        <v-card flat :disabled="autoTune != 0">
          <v-card-title>Manual Tuning</v-card-title>
          <v-card-text>
            <v-form>
              <v-row >
                <v-col align="center">
                  <v-combobox
                    class="mt-0 pt-0"
                    v-model="satellite"
                    :items="satellites"
                    label="Satellite Name"
                    style="width: 240px"
                    append-icon=""
                  >
                    <span slot="append"><HelpTooltip customClass="ml-2" text="Use the names from the list for supported satellites. Otherwhise, received packets will not be linked to the satellite." /></span> 
                  </v-combobox>
                </v-col>
                <v-col align="center">
                  <v-text-field
                    v-model="custom.NORAD"
                    class="mt-0 pt-0"
                    type="number"
                    label="NORAD"
                    style="width: 100px"
                    step="1"
                    min="1000"
                    max="9999999"
                  >
                  </v-text-field>
                </v-col>
                <v-col align="center">
                  <v-text-field
                    v-model="custom.freq"
                    class="mt-0 pt-0"
                    type="number"
                    label="Frequency"
                    style="width: 100px"
                    step="0.1"
                  >
                    <span slot="append">MHz</span>
                  </v-text-field>
                </v-col>
                <v-col align="center">
                  <v-select class="mt-0 pt-0" v-model="custom.mode" :items="modes" style="width: 80px" label="Mode"></v-select>
                </v-col>
                <v-col align="center" v-if="custom.mode=='LoRa'">
                  <v-text-field
                    v-model="custom.sf"
                    class="mt-0 pt-0"
                    type="number"
                    label="Spreading Factor"
                    tooltip="Spreading Factor"
                    style="width: 120px"
                    step="0.1"
                    min="7"
                    max="12"
                  >
                    <span slot="append">MHz </span> 
                  </v-text-field>
                  
                </v-col>
                <v-col align="center">
                  <v-text-field
                    v-model="custom.bw"
                    class="mt-0 pt-0"
                    type="number"
                    label="Bandwidth"
                    style="width: 100px"
                    step="125"
                    min="125"
                    max="500"
                  >
                    <span slot="append">kHz</span>
                  </v-text-field> 
                </v-col>
                <v-col align="center" v-if="custom.mode=='LoRa'">
                  <v-text-field
                    v-model="custom.cr"
                    class="mt-0 pt-0"
                    type="number"
                    label="Coding Rate"
                    style="width: 70px"
                    step="1"
                    min="5"
                    max="8"
                  >
                  </v-text-field> 
                </v-col>
                <v-col align="center" v-if="custom.mode=='LoRa'">
                  <v-text-field
                    v-model="custom.sw"
                    class="mt-0 pt-0"
                    type="number"
                    label="Syncword"
                    style="width: 80px"
                    step="1"
                    min="0"
                    max="65535"
                  >
                  </v-text-field> 
                </v-col>
                <v-col align="center" v-if="custom.mode=='LoRa'">
                  <v-select class="mt-0 pt-0" v-model="custom.crc" :items="booleans" style="width: 90px" label="CRC"></v-select>
                </v-col>
                <v-col align="center">
                  <v-text-field
                    v-model="custom.pl"
                    class="mt-0 pt-0"
                    type="number"
                    label="Preamble length"
                    style="width: 70px"
                    step="1"
                    min="4"
                    max="12"
                  >
                  </v-text-field> 
                </v-col>
                <v-col align="center" v-if="custom.mode=='LoRa'">
                  <v-select class="mt-0 pt-0" v-model="custom.fldro" :items="fldros" style="width: 90px" label="FLDRO"></v-select>
                </v-col>
                <v-col align="center" v-if="custom.mode=='LoRa'">
                  <v-text-field
                    v-model="custom.cl"
                    class="mt-0 pt-0"
                    type="number"
                    label="Current limit"
                    style="width: 80px"
                    step="1"
                    min="0"
                    max="250"
                  >
                  </v-text-field> 
                </v-col>
                <v-col align="center" v-if="custom.mode=='LoRa'">
                  <v-text-field
                    v-model="custom.gain"
                    class="mt-0 pt-0"
                    type="number"
                    label="Gain"
                    style="width: 80px"
                    step="1"
                    min="0"
                    max="30"
                  >
                  </v-text-field> 
                </v-col>
                <v-col align="center">
                  <v-text-field
                    v-model="custom.pwr"
                    class="mt-0 pt-0"
                    type="number"
                    label="TX Power"
                    style="width: 80px"
                    step="1"
                    min="-17"
                    max="30"
                  >
                  </v-text-field> 
                </v-col>
                <v-col align="center" v-if="custom.mode=='FSK'">
                  <v-text-field
                    v-model="custom.br"
                    class="mt-0 pt-0"
                    type="number"
                    label="Bitrate"
                    style="width: 100px"
                    step="1"
                    min="0"
                    max="255"
                  >
                  </v-text-field> 
                </v-col>
                <v-col align="center" v-if="custom.mode=='FSK'">
                  <v-text-field
                    v-model="custom.fd"
                    class="mt-0 pt-0"
                    type="number"
                    label="Frequency deviation"
                    style="width: 100px"
                    step="1"
                    min="0"
                    max="255"
                  >
                  </v-text-field> 
                </v-col>
                <v-col align="center" v-if="custom.mode=='FSK'">
                  <v-text-field
                    v-model="custom.fsw"
                    class="mt-0 pt-0"
                    type="text"
                    label="Syncword"
                    style="width: 100px"
                  >
                  </v-text-field> 
                </v-col>
                <v-col align="center" v-if="custom.mode=='FSK'">
                  <v-text-field
                    v-model="custom.len"
                    class="mt-0 pt-0"
                    type="number"
                    label="Length"
                    style="width: 100px"
                  >
                  </v-text-field> 
                </v-col>
                <v-col align="center" v-if="custom.mode=='FSK'">
                  <v-select class="mt-0 pt-0" v-model="custom.ook" :items="ooks" style="width: 90px" label="Data shaping"></v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
        <v-row justify="center">
          <v-btn class="mb-4" @click="send()" :disabled="btnSaveDisabled" v-show="!sending" color="primary">{{ btnSaveText }}</v-btn>
          <v-progress-circular indeterminate color="primary" v-show="sending"></v-progress-circular>
          
        </v-row>
        <v-row justify="center"><v-alert  type="error" dense outlined v-if="sendError">{{sendError}}</v-alert></v-row>
        <v-card :disabled="!station.tx" class="mt-8">
          <v-card-title>
            <h3>
              Transmission
            </h3>
          </v-card-title>
          <v-card-text>
          <div v-if="!station.tx" class="red--text font-weight-bold">Transmission is disabled on your station by hardware configuration. This is a security feature, you can allow transmission in the local station configuration dashboard.</div>
          <div v-if="station.tx" class="font-weight-italic">The following options will physically transmit from your station hardware. Make sure you follow your local regulations, that your antenna is connected and there is no LNA connected.</div>
          </v-card-text>
          <v-tabs v-model="tab" grow>
            <v-tab key="text">text</v-tab>
            <v-tab key="binary">binary</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item key="text">
              <v-card flat>
                <v-card-text>
                  This option will transmit a plain text message from your station. It will be encoded in 8 bit ASCII.
                  <v-row class="mt-5">
                    <v-col> 
                      <v-textarea v-model="txStr" maxlength="25" no-resize rows="2" label="Message to transmit" ></v-textarea>
                    </v-col>
                    <v-col> 
                      <v-btn height="5em" :disabled="txDisabled" @click="sendTx()" color="primary">{{txDisabled?"TX Sent": "Send TX"}} <v-icon right dark>mdi-antenna</v-icon></v-btn>
                      <v-btn class="ml-3" height="5em" :disabled="txDisabled" @click="sendTest()" color="primary">{{txDisabled?"Test Sent": "Send Test Frame"}} <v-icon right dark>mdi-antenna</v-icon></v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item key="binary">
              <v-card flat>
                <v-card-text>
                  <v-text-field v-model="txB64" type="text" label="Base64 text" :error-messages="txErrors"></v-text-field>
                  <v-text-field v-model="txHex" type="text" label="Hex text" :error-messages="txErrors"></v-text-field> 
                  <HexView :raw="base64ToArrayBuffer(txB64)"/>
                  <v-row class="pa-4 mt-4" justify="center">
                    <v-btn :disabled="txDisabled" @click="sendTx()" color="primary">{{txDisabled?"TX Sent": "Send TX"}} <v-icon right dark>mdi-antenna</v-icon></v-btn>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-card-text>
    </v-card>
    <v-snackbar
          v-model="snackbar.show"
          >
            <b>{{ snackbar.title }}</b><br>
            <i>{{ snackbar.description }}</i>

            <template v-slot:action="{ attrs }">
              <v-btn
                color="primary"
                text
                v-bind="attrs"
                @click="snackbar.show = false"
              >
                Close
              </v-btn>
          </template>
        </v-snackbar>
  </v-dialog>
</template>

<script>
const axios = require("axios");
import HelpTooltip from './HelpTooltip.vue'
import HexView from './HexView.vue'

export default {
  name: "StationOperate",
  props: [
    "station"
  ],
  components:{
    HelpTooltip,
    HexView
  },
  data(){
    return {
      modal: null,
      satellite: '',
      config: '',
      satellites: [],
      satInfo: {},
      custom: {
        mode: null,
        freq: null,
        bw: null,
        sf: null,
        cr: null,
        sw: null, 
        pwr: null,
        cl: null,
        pl: null,
        gain: null,
        crc: null,
        fldro: null,
        NORAD: null,
        br: null, //fsk
        fd: null,
        ook: null,
        fsw: null,
        len: null
      },
      modes: ['LoRa', 'FSK'],
      ooks: [{text:'Disabled', value: 0}, {text:'0.3', value: 1}, {text:'0.5', value: 2}, {text:'0.7', value: 3}, {text:'1.0', value: 4}],
      booleans: [{text:'Enable', value: true}, {text:'Disable', value: false}],
      fldros: [{text:'Enable', value: 1}, {text:'Disable', value: 0}, {text:'Auto', value: 2}],
      btnSaveText: null,
      btnSaveDisabled: true,
      tab: null,
      txDisabled: false,
      txStr: "",
      txB64: "",
      txHex: "",
      txErrors: [],
      snackbar: {text:"", show:false},
      autoTunes: [{text:'Enabled 137', value: 137},{text:'Enabled 433', value: 433}, {text:'Enabled 868-915', value: 900}, {text:'Enabled 2.4G', value: 2400}, {text:'Disabled', value: 0} ],
      autoTune: null,
      sending: false,
      sendError: ""
    }
  },
  beforeMount(){
    this.autoTune = this.station.autoTune
  },
  methods: {
    populateParameters(manualOverride=false) {
      let conf = {}

      if (this.satInfo[this.satellite] && !manualOverride)
        conf = this.satInfo[this.satellite].configurations[0]
      else
        conf = JSON.parse(this.station.modem_conf)

      this.custom.freq = conf.freq
      this.custom.mode = conf.mode
      this.custom.bw = conf.bw
      this.custom.sf = conf.sf
      this.custom.cr = conf.cr
      this.custom.sw = conf.sw
      this.custom.pwr = conf.pwr
      this.custom.cl = conf.cl
      this.custom.pl = conf.pl
      this.custom.gain = conf.gain
      this.custom.crc = conf.crc
      this.custom.fldro = conf.fldro
      this.custom.NORAD = conf.NORAD
      this.custom.br = conf.br
      this.custom.fd = conf.fd
      this.custom.ook = conf.ook
      if (typeof conf.fsw === 'string' || conf.fsw instanceof String)
        this.custom.fsw = conf.fsw
      else
        this.custom.fsw = JSON.stringify(conf.fsw)
      this.custom.len = conf.len
    },
    async getSatellites() {


       // Making two requests simultaneously
    const [satellites, balloons] = await Promise.all([
        axios.get(`${process.env.VUE_APP_API_ENDPOINT}/v1/satellites/?status=Supported`),
        axios.get(`${process.env.VUE_APP_API_ENDPOINT}/v1/satellites/?status=Balloon`)
    ]);

    // Extracting data from both responses
    const data1 = satellites.data;
    const data2 = balloons.data;

    // Combining the data from both requests
    const data = [...data1, ...data2]; // Or any other logic to merge the data


     // const { data } = await axios.get(`${process.env.VUE_APP_API_ENDPOINT}/v1/satellites/?status=Supported`) + await axios.get(`${process.env.VUE_APP_API_ENDPOINT}/v1/satellites/?status=Balloon`);
      //console.log(data);
      
      this.satellites = []
      for (const sat of data) {
        this.satInfo[sat.name] = sat
        this.satellites.push(sat.name)
      }
      
      this.satellite = this.station.satellite
      this.$nextTick(() => { // fire only after the watch events have fired
        if (!this.autoTune) {
          this.populateParameters(true) // make sure the vales are take from the modem_conf when the db has autotune off
        }
        this.btnSaveText = "Config Saved"
        this.btnSaveDisabled = true
      })
    },
    async send() {
      let params = {}

      params.autoTune = this.autoTune
      if (this.autoTune == 0) {
        let modem_conf = {}
        modem_conf.mode = this.custom.mode
        modem_conf.sat = this.satellite
        modem_conf.NORAD = this.custom.NORAD
        modem_conf.freq = this.custom.freq
        modem_conf.bw = this.custom.bw
        modem_conf.pl = this.custom.pl
        modem_conf.pwr = this.custom.pwr

        if (this.custom.mode == "LoRa") {
          modem_conf.sf = this.custom.sf
          modem_conf.cr = this.custom.cr
          modem_conf.sw = this.custom.sw
          modem_conf.crc = this.custom.crc
          modem_conf.fldro = this.custom.fldro
          modem_conf.cl = this.custom.cl
          modem_conf.gain = this.custom.gain
        }
        else {
          modem_conf.br = this.custom.br
          modem_conf.fd = this.custom.fd
          try {
            modem_conf.fsw = JSON.parse(this.custom.fsw)
          } catch (e) {
            modem_conf.fsw = []
          }
          modem_conf.ook = this.custom.ook
          modem_conf.len = this.custom.len
        }
        params.modem_conf = JSON.stringify(modem_conf)
      }

      let config = {
        headers: {
          sessionToken: localStorage.sessionToken,
          userId: localStorage.userId
        }
      }

      this.sending = true
      //console.log(this.modemConf);
      let error = null
      await axios.put(`${process.env.VUE_APP_API_ENDPOINT}/v1/station/${this.$route.params.id}`, params, config)
        .catch(function (err) {
          error = err
        })

      this.sending = false
      
      if (!error) {
        this.btnSaveDisabled = true
        this.btnSaveText = "Config saved"
        this.sendError = ""
        if (this.autoTune == 0) {
          this.station.satellite = this.satellite
        }
        //this.$emit("sent");
      }
      else {
        if (error.response && error.response.status == 409) {
          this.sendError = "It was not possible to communicate with the station. It is no longer online. Please reconnect the station and try again."
        }
        else {
          this.sendError = "Error sending your request. Please try again later."
        }
      }
      
    },
    base64ToArrayBuffer(base64) {
        var binary_string = atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    },
    async sendTx() {
      if (!this.txB64) {
        this.snackbar.title = "You cannot send an empty payload!"
        this.snackbar.description = ""
        this.snackbar.show = true;
        return;
      }
        
      this.snackbar.title = "TX successful from your station!"
      this.snackbar.description = "Please, be aware that nearby stations might also want to transmit. Do not flood."
      this.snackbar.show = true;
      setTimeout(() => this.txDisabled = false , 1000);
      this.txDisabled = true;
      let params = {
        tx: this.txB64
      }
      let config = {
        headers: {
          sessionToken: localStorage.sessionToken,
          userId: localStorage.userId
        }
      }
      //console.log(this.modemConf);
      try {
        await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/v2/station/${this.$route.params.id}/tx`, params, config);
      } catch (err) {
        //console.log(JSON.stringify(err))
      }
    },
    async sendTest() {
      this.snackbar.title = "Test frame sent!"
      this.snackbar.description = "Please, do not overuse this feature to keep the system available for real packets."
      this.snackbar.show = true;
      setTimeout(() => this.txDisabled = false , 20000);
      this.txDisabled = true;
      let params = {
      }
      let config = {
        headers: {
          sessionToken: localStorage.sessionToken,
          userId: localStorage.userId
        }
      }
      //console.log(this.modemConf);
      try {
        await axios.post(`${process.env.VUE_APP_API_ENDPOINT}/v1/station/${this.$route.params.id}/test`, params, config);
      } catch (err) {
        console.log(JSON.stringify(err))
      }
    },
  },
  watch: {
    custom: {
      deep: true,
      handler() {
        this.btnSaveDisabled = false
        this.btnSaveText = "Save config"
      }
    },
    autoTune() {
      this.btnSaveDisabled = false
      this.btnSaveText = "Save config"
    },
    modal(){
      if (!this.modal || this.satellites.length != 0)
        return

      this.getSatellites()
    },
    txStr() {
      this.txB64 = btoa(this.txStr)
    },
    txB64() {
      try {
        var binary_string = atob(this.txB64);
        var len = binary_string.length;
        var bytes = "";
        for (var i = 0; i < len; i++) {
            let char = binary_string.charCodeAt(i)
            if (char < 16)
              bytes += '0' + char.toString(16).toUpperCase();
            else
              bytes += char.toString(16).toUpperCase();
            if (i+1 < len) {
              bytes += ' '
            }
        }
        this.txHex = bytes
        this.txErrors = []
      }
      catch {
        this.txErrors = "Invalid base64 string"
      }
      
    },
    txHex() {
      try {
        this.txB64 = Buffer.from(this.txHex.replace(/\s/g, ''), "hex").toString("base64")
        this.txErrors = []
      }
      catch {
        this.txErrors = "Invalid hex string"
      }
    },
    satellite() {
      this.populateParameters()
    }
  },
}
</script>

<style>

</style>