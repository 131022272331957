<template>
  <v-card class="mt-5">
    <v-card-title><h2>Edit station parameters</h2></v-card-title>
    <v-card-text class="text-center">
      <v-form>
        <v-layout row wrap v-if="station">
          <!-- column 1 -->
          <v-flex pa-6 mt-2 xs12 sm6> 
            <v-text-field v-model="station.name" label="Name" readonly filled></v-text-field>
            <v-textarea label="Description" rows="3" counter="500" filled v-model="station.description"></v-textarea>
            <v-select
              :items="antennaTypes"
              v-model="station.antenna"
              filled
              label="Antenna Type"
            ></v-select>
            <v-range-slider
              label="Antenna range"
              v-model="antennaBand"
              min="90"
              max="2500"
              :hint="`${antennaBand[0]} - ${antennaBand[1]} MHz`"
              persistent-hint
            >
            </v-range-slider>
            <v-row>
              <v-col cols="4" >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-on="on" v-bind="attrs">
                      <v-switch v-model="station.test" label="Test mode"></v-switch>
                    </div>
                  </template>
                  <span>
                    While the test mode is enabled on a station, all the received packets are marked as test packets (not linked to satellites in orbit). <br />
                    Stations <u>must</u> be in test mode while making tests with SDR retransmissions or receiving packets from unlaunched prototypes.
                  </span>
                </v-tooltip>
              </v-col>
              <v-col cols="4" >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-on="on" v-bind="attrs">
                      <v-switch v-model="station.autoUpdate" label="Auto update"></v-switch>
                    </div>
                  </template>
                  <span>
                    When auto update is enabled, your station will receive unattended automatic updates from the TinyGS server. <br />
                    This is recommended to keep your station updated with the latest software improvements.
                  </span>
                </v-tooltip>
              </v-col>
              <v-col cols="4">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-on="on" v-bind="attrs">
                      <v-switch v-model="station.beta" label="Beta firmware" :disabled="!station.autoUpdate"></v-switch>
                    </div>
                  </template>
                  <span>When beta firmware is enabled your station will receive more frequent updates from the experimental branch of TinyGS.<br />
                    <u>WARNING:</u> this is an advanced feature. Experimental changes might malfunction in some boards, so be ready to manually flash your station<br />
                    again if this happens. Do not enable this for stations on the roof or in a hard to reach location.
                  </span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row>
              <!--<v-col cols="4" >
                <v-switch v-if="station.version >= 2107210"
                  v-model="station.lowPower"
                  label="Low power"
                ></v-switch>
              </v-col>-->
              
              
            </v-row>
          </v-flex>
          <!-- column 1 -->
          <v-flex pa-6 mt-2 xs12 sm6> 
            <vue-dropzone ref="myVueDropzone" id="dropzone" :options="dropzoneOptions" :useCustomSlot=true v-on:vdropzone-success="fileAdded" v-on:vdropzone-removed-file="removedFile">
              <div class="dropzone-custom-content">
                <h3 class="dropzone-custom-title primary--text">Drag and drop to upload photos of your station!</h3>
                <div class="subtitle">...or click to select a picture from your computer</div>
              </div>
            </vue-dropzone>
            <div v-if="picturesPendingValidation" class="error--text">Some of the files are waiting for aproval. This usually takes a few hours.</div>
          </v-flex>
        </v-layout>
        <v-btn class="mx-2" @click="cancel()" color="error" v-show="!sending">Cancel</v-btn>
        <v-btn class="mx-2" @click="send()" :color="saveError?'error':'primary'" v-show="!sending">{{saveText}}</v-btn>
        <v-progress-circular indeterminate color="primary" v-show="sending"></v-progress-circular>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
const axios = require("axios");
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
  name: "EditStation",
  props: [
    "station",
  ],
  components: {
    vueDropzone: vue2Dropzone
  },
  data(){
    return {
      satellites: null,
      overlay: false,
      rules: [
        value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
      ],
      dropzoneOptions: {
          url: `${process.env.VUE_APP_API_ENDPOINT}/v1/station/${this.$route.params.id}/image`,
          thumbnailWidth: 150,
          maxFilesize: 3,
          resizeWidth: 1080,
          maxFiles:10,
          addRemoveLinks: true,
          headers: { 
            sessionToken: localStorage.sessionToken,
            userId: localStorage.userId
          },
          dictRemoveFileConfirmation: "Are you sure? This action cannot be undone."
      },
      picturesPendingValidation: false,
      antennaTypes: [
        "Dipole ",
        "V-Dipole",
        "Discone",
        "1/4λ Collinear",
        "5/8λ Collinear",
        "Eggbeater",
        "Eggbeater 2 ",
        "1/4λ Ground plane",
        "5/8λ Ground plane",
        "Helical",
        "Inverted-F",
        "J-pole",
        "Lindenblad",
        "Lindenblad Parasitic",
        "Moxon",
        "Parabolic ",
        "Patch",
        "QFH Quadrifilar",
        "Turnstile",
        "Random Wire",
        "Rusty Nail",
        "Slim-Jim",
        "Yagi-Uda",
        "Yagi-Uda Cross"
      ],
      antennaBand: [100, 960],
      sending: false,
      saveError: false,
      preventDelete: false,
      newFiles: []
    }
  },
  beforeMount() {
    if (this.station.antennaBand){
      this.antennaBand = this.station.antennaBand
    }
      
    this.getSatellites();
  },
  mounted() {

    if (!this.station.pictures)
      return; 
    
    for (const picture of this.station.pictures) {
      if (picture.status == 0)
        this.picturesPendingValidation = true
      if (picture.status < 0)
        continue

      let file = { size: picture.size, name: picture.origName, type: picture.type, id: picture.shortId}
      if (picture.status == 1)
        this.$refs.myVueDropzone.manuallyAddFile(file, picture.src)
      else
        this.$refs.myVueDropzone.manuallyAddFile(file, `${process.env.VUE_APP_STATIC_HOST}/stationImages/validation-pending.png`)
    }   
  },
  methods: {
    async getSatellites() {
      const { data } = await axios.get(`${process.env.VUE_APP_API_ENDPOINT}/v1/satellites/?status=Supported`);
      //console.log(data);
      
      this.satellites = data.map(x => x.name);
    },
    async send() {
      this.preventDelete = true
      let params = {
        description: this.station.description.replace(/<[^>]+>/g, ''),
        antenna: this.station.antenna,
        antennaBand: this.antennaBand,
        test: this.station.test,
        autoUpdate: this.station.autoUpdate,
        beta: this.station.beta
      }
      let config = {
        headers: {
          sessionToken: localStorage.sessionToken,
          userId: localStorage.userId
        }
      }
      this.sending = true

      try {
        await axios.put(`${process.env.VUE_APP_API_ENDPOINT}/v1/station/${this.$route.params.id}`, params, config);
      } catch (err) {
        console.log(JSON.stringify(err))
        this.saveError = true
        this.sending = false
        return
      }

      params.newFiles = this.newFiles
      this.$emit("configSaved", params);
    },
    cancel(){
      this.preventDelete = true
      this.$emit("cancel", this.newFiles);
    },
    fileAdded(file) {
      this.picturesPendingValidation = true
      this.newFiles.push({status: 0, size: file.size, origName: file.name, type: file.type, shortId: file.responseText})
    },
    async removedFile(file) {
      if (this.preventDelete){
        return
      }
      let config = {
        headers: {
          sessionToken: localStorage.sessionToken,
          userId: localStorage.userId
        }
      }
      if (file.id) {
        await axios.delete(`${process.env.VUE_APP_API_ENDPOINT}/v1/station/${this.$route.params.id}/image/${file.id}`, config);
      }
      else {
        await axios.delete(`${process.env.VUE_APP_API_ENDPOINT}/v1/station/${this.$route.params.id}/image/${file.xhr.responseText}`, config);
      }
    }
  },
  computed: {
    saveText() {
      return this.saveError?'Error':'Save'
    }
  }
}
</script>

<style>

</style>