<template>
  <nav>
    <v-app-bar app color="primary" dark height="70">
      <v-app-bar-nav-icon @click="drawer=!drawer" class="mr-4"></v-app-bar-nav-icon>

      <div class="flex-grow-1 d-flex justify-center">
        <div class="d-flex">
          <v-img contain transition="fade-transition" max-height="60" alt="TinyGS logo" src="../assets/logo.svg" />
        </div>
      </div>

      <v-btn icon class="mr-2" v-if="user">
        <v-icon @click="$router.push(`/user/${user}`)" large>mdi-account-circle</v-icon>
      </v-btn>
      <v-btn @click="logout()" color="primary" class="lighten-1" depressed v-if="user">
        <span>Sign Out</span>
        <v-icon right>mdi-exit-to-app</v-icon>
      </v-btn>
      <!--<Login /> -->
    </v-app-bar >
    <v-navigation-drawer disable-resize-watcher v-model="drawer" app color="primary" class="darken-1">
      <div class="d-flex justify-center mt-3 mb-4">
        <v-img contain max-height="90" transition="scale-transition" alt="TinyGS logo" src="../assets/logo.svg" />
      </div>
      <v-divider color="white"></v-divider>
      <v-list dense>
        <v-list-item-group flat class="white--text">
          <v-list-item dark v-for="link in links" :key="link.text" router :to="link.path">
            <v-list-item-icon>
              <v-icon v-text="link.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="link.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group dense
          :value="false"
          prepend-icon="$satellite"
          no-action
          sub-group>
          <template v-slot:activator>
            <v-list-item-content flat class="white--text">
              <v-list-item-title dark>Satellites</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item dark v-for="link in satellites" :key="link.text" router :to="link.path">
          <!--         <v-list-item-icon>
              <v-icon v-text="link.icon"></v-icon>
            </v-list-item-icon> -->
            <v-list-item-content>
              <v-list-item-title v-text="link.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-group dense
          :value="false"
          prepend-icon="$rotateOrbit"
          no-action
          sub-group
     >

          <template v-slot:activator>
            <v-list-item-content flat class="white--text">
            <v-list-item-title >About</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item dark v-for="link in about" :key="link.text" router :to="link.path">
            <v-list-item-content>
              <v-list-item-title v-text="link.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        
        </v-list-item-group>  
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>


export default {
  props: [
    "user"
  ],
  data() {
    return {
      drawer: false,
      links: [
        { text: 'Home', icon: 'mdi-home', path: '/' },
        { text: 'Stations', icon: '$station', path: '/stations' },
        { text: 'Packets', icon: 'mdi-file-document-outline', path: '/packets' },
      ],

      satellites: [
        { text: 'Active Satellites',  icon: '$satellite',  path: '/satellites' },
        { text: 'SWARM Satellites', icon: '$satellite', path: '/satellitesSB' },
        { text: 'Starlink Satellites', icon: '$satellite', path: '/satellitesSL' },
        { text: 'Inactive Satellites', icon: '$satellite', path: '/satellitesIna' },
        { text: 'Future Satellites', icon: '$satellite', path: '/satellitesFut' },
        { text: 'Balloons', icon: '$balloon', path: '/balloons' },
    ],
    about: [
        { text: 'Meet the team', icon: 'mdi-home', path: '/about' },
        { text: 'History', icon: '$station', path: '/history' },
        { text: 'Terms', icon: '$station', path: '/terms' },
      ],

    }
  },
  methods: {
    logout(){
      localStorage.removeItem("userId")
      localStorage.removeItem("sessionToken")
      this.$emit("logout", true)
    }
  }
}
</script>

<style>
</style>