<template>
<div class="station">
  <v-container v-show="!editView">
    <v-layout row wrap v-if="station" mt-4>
      <v-flex > 
      <h1 class="heading grey--text text--darken-1" >
            <v-btn @click="$router.go(-1)" class="mr-2" depressed>
              <v-icon  large>mdi-arrow-left</v-icon>
              <span>Back</span>
            </v-btn>
            {{ station.name }} Console</h1>
      </v-flex>
      <v-flex > </v-flex>
      <v-flex mr-8 class="text-right" v-if="isCurrentUser(station.userId)">

        <v-btn class="mr-2" @click="editView=true" color="success">
          <v-icon left>mdi-pencil</v-icon>
          <span>Edit station</span>
        </v-btn>

        <StationOperate :station="station"/>
      </v-flex>

    </v-layout>
    <v-layout row wrap v-if="station">
      <!-- column 1 -->
      <v-flex pa-4 mt-2 xs12 sm6 > 
      <!-- description -->
        <v-card flat class="mr-5 my-3 pa-2 grey--text text--darken-3">
          <v-carousel v-if="confirmedPictures && confirmedPictures.length > 0">
            <v-carousel-item
              v-for="(picture,i) in confirmedPictures"
              :key="i"
              :src="picture.src"
              reverse-transition="fade-transition"
              transition="fade-transition"
            ></v-carousel-item>
          </v-carousel>
          <v-card-text class="mt-3 grey--text text--darken-3 mx-auto text-center">
            <v-layout row wrap>
              <v-flex pa-2 xs6 sm6 md6 lg3 xl3>
                <div class="caption grey--text">Status</div>
                <status-indicator class="mr-1" :status="station.status==1?'positive':'negative'" :pulse="station.status==1" /> <strong v-html="(station.status==1) ? '<span class=\'green--text\'>Online</span>' : '<span class=\'red--text\'>Offline</span>'"></strong>
              </v-flex>
              <v-flex pa-2 xs6 sm6 md6 lg3 xl3>
                <div class="caption grey--text">Listening</div>
                <div >{{station.satellite}}</div>
              </v-flex>
              <v-flex pa-2 xs6 sm6 md6 lg3 xl3>
                <div class="caption grey--text">Version</div>
                <div >{{station.version}}</div>
              </v-flex>
              <v-flex pa-2 xs6 sm6 md6 lg3 xl3>
                <div class="caption grey--text">Creation date</div>
                <div >{{dateSince(station.creationDate)}}</div>
              </v-flex>
              <v-flex pa-2 xs6 sm6 md6 lg3 xl3>
                <div class="caption grey--text">Last seen</div>
                <div >{{dateSince(station.lastSeen)}}</div>
              </v-flex>
              <v-flex pa-2 xs6 sm6 md6 lg3 xl3>
                <div class="caption grey--text">Last Packet</div>
                <div >{{dateSince(station.lastPacketTime)}}</div>
              </v-flex>
              <v-flex pa-2 xs6 sm6 md6 lg3 xl3>
                <div class="caption grey--text">Position (Lat, Long)</div>
                <div >{{station.location[0]}}, {{station.location[1]}}</div>
              </v-flex>
              <v-flex pa-2 xs6 sm6 md6 lg3 xl3>
                <div class="caption grey--text">QTH Locator</div>
                <div >{{latLngToLocator(station.location[0], station.location[1])}}</div>
              </v-flex>
              <v-flex pa-2 xs6 sm6 md6 lg3 xl3>
                <div class="caption grey--text">Elevation</div>
                <div >{{(station.elevation||0).toFixed(2)}} m</div>
              </v-flex>
              <v-flex pa-2 xs6 sm6 md6 lg3 xl3>
                <div class="caption grey--text">Auto tuning</div>
                <div >{{station.autoTune?station.autoTune:"OFF"}}</div>
              </v-flex>
              <v-flex pa-2 xs6 sm6 md6 lg3 xl3>
                <div class="caption grey--text">Test mode</div>
                <div >{{station.test?"ON":"OFF"}}</div>
              </v-flex>
              <v-flex pa-2 xs6 sm6 md6 lg3 xl3>
                <div class="caption grey--text">Confirmed packets</div>
                <div >{{station.confirmedPackets }}</div>
              </v-flex>
              <v-flex pa-2 xs6 sm6 md6 lg3 xl3>
                <div class="caption grey--text">Telemetry packets</div>
                <div >{{station.telemetryPackets}}</div>
              </v-flex>
              <v-flex pa-2 xs6 sm6 md6 lg3 xl3>
                <div class="caption grey--text">Type of antenna</div>
                <div >{{station.antenna ? station.antenna : "- -"}}</div>
              </v-flex>
              <v-flex pa-2 xs6 sm6 md6 lg3 xl3>
                <div class="caption grey--text">Band</div>
                <div >{{station.antennaBand ? `${station.antennaBand[0]} - ${station.antennaBand[1]} MHz` : "- -"}}</div>
              </v-flex>
              <v-flex pa-2 xs6 sm6 md6 lg3 xl3>
                <div class="caption grey--text">Record distance</div>
                <div >{{ station.recordDistance ? (station.recordDistance||0).toFixed(1) + " Km" : "- -"}}</div>
              </v-flex>
              <v-flex pa-2 xs6 sm6 md6 lg3 xl3 v-if="isCurrentUser(station.userId)">
                <div class="caption grey--text">Local IP</div>
                <div ><a target="blank" :href="`http://${station.local_ip}/`">{{station.local_ip}}</a></div>
              </v-flex>
              <v-flex pa-2 xs6 sm6 md6 lg3 xl3 v-if="isCurrentUser(station.userId)">
                <div class="caption grey--text">Auto update</div>
                <div >{{station.autoUpdate?"ON":"OFF"}}</div>
              </v-flex>
              <v-flex pa-2 xs6 sm6 md6 lg3 xl3 v-if="isCurrentUser(station.userId)">
              <div class="caption grey--text">Wifi RSSI</div>
              <div :class="{ 'green--text font-weight-bold': station.wifiRssi >= -70, ' red--text ': station.wifiRssi < -80 }">
                {{
                  station.wifiRssi ? (
                    station.wifiRssi >= -60 ? "Very Good"  : (
                      station.wifiRssi >= -70 ? "Good" : (
                        station.wifiRssi >= -80 ? "Low" : "Very low"
                      )
                    )
                  ) : "None"
                }}
              </div>
            </v-flex>
              <v-flex pa-2 xs6 sm6 md6 lg3 xl3 v-if="isCurrentUser(station.userId)">
                <div class="caption grey--text">Radio Status</div>
  <div :class="{ 'green--text font-weight-bold': station.radioStatus === 0, 'red--text font-weight-bold': station.radioStatus !== 0 }">
    {{
      station.radioStatus === undefined ? "None" : (
        station.radioStatus === 0 ? "Ready" : `Error: ${station.radioStatus}`
      )
    }}
  </div>
              </v-flex>
              <v-flex pa-2 xl12 v-if="station.description">
                <div class="caption grey--text">Description</div>
                <div class=" px-8">{{station.description}}</div>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
        <v-card flat class="mr-5 my-3 pa-2 grey--text text--darken-3">
          <v-card-text class="grey--text text--darken-3 mx-auto">
            <PacketPerDayBarGraph v-if="chartdata" :chartdata="chartdata" />
          </v-card-text>
        </v-card>

      </v-flex>

      <!-- Column 2 -->
      <v-flex pa-4 mt-2 xs12 sm6 class="grey--text text--darken-1">
        <!-- satvis -->
        <v-card flat class="mr-5 my-3 pa-2 grey--text text--darken-3" style="min-height:500px;">
          <iframe id="satvis-frame" allowtransparency="true" :src="`${viewerHost}/?elements=Point,Label,Orbit,Sensor-cone,Ground-station-link&layers=OfflineHighres&gs=${station.location[0]},${station.location[1]}&tags=TinyGS`" class="satvis" :class="{'satvis-fullScreen': satvisFullScreen, 'satvis-normal': !satvisFullScreen}"></iframe>
          <div class="credits-satvis">Powered by <a href="https://github.com/Flowm/satvis">Satvis</a> (MIT)</div>
          <v-btn @click="satvisFullScreen = !satvisFullScreen" class="mr-2" :class="{'fullScreen-btn-fullScreen': satvisFullScreen, 'fullScreen-btn-normal': !satvisFullScreen}" icon>
            <v-img contain transition="fade-transition" max-height="60" src="../assets/fullScren-but.png" />
          </v-btn>
        </v-card>

        <!-- RX map -->
        <v-card flat class="mr-5 my-3 pa-4 grey--text text--darken-3">
          <PacketMapGS :packets="packetsMap" :station="station" :statistics="statistics" />
        </v-card>
      
      </v-flex>

      <!-- Packets -->
      <v-flex xs12 sm12 pa-4>
        <div v-for="packet in packets" :key="packet.packetId"> 
          <PacketRowGS :packet="packet" />
          <v-divider></v-divider>
        </div>
      </v-flex>
    </v-layout>
    <div v-else> <!-- loading spinner -->
      <v-row class="fill-height ma-16" align="center" justify="center">
        <v-progress-circular indeterminate color="grey"></v-progress-circular>
      </v-row>
    </div>
    <v-row justify="center" v-if="station && isCurrentUser(station.userId)">
      <v-progress-circular v-if="loadingPackets"
        indeterminate
        color="primary"
      ></v-progress-circular>
      <v-btn v-else v-show="packets.length > 0" :supressed="!loadingPackets" @click="getPackets()" elevation="2">Load more</v-btn>
    </v-row>
  </v-container>
  <v-container v-if="editView">
    <EditStation v-on:configSaved="configSaved" v-on:cancel="configCancel" :station="station"/>
  </v-container>
</div>

</template>

<script>
const axios = require("axios");
import PacketRowGS from '../components/PacketRowGS.vue'
import EditStation from '../components/EditStation.vue'
import PacketMapGS from '../components/PacketMapGS.vue'
import moment from 'moment'
import "leaflet/dist/leaflet.css"
import PacketPerDayBarGraph from '../components/PacketPerDayBarGraph.vue'
import { StatusIndicator } from 'vue-status-indicator';
import StationOperate from '../components/StationOperate.vue'

export default {
  components: {
    EditStation,
    PacketRowGS,
    PacketMapGS,
    PacketPerDayBarGraph,
    StatusIndicator,
    StationOperate
  },
  data() {
    return {
      station: null,
      packets: [],
      satvisFullScreen: false,
      txStr: "",
      txDisabled: false,
      customTemplates: null,
      base64Cb: false,
      chartdata:null,
      loadingPackets: false,
      lastPacketLoad: 0,
      packetLoadCount: 0,
      editView: false,
      packetsMap: null,
      statistics: null,
      viewerHost: process.env.VUE_APP_VIEWER_HOST,
    }
  },
  beforeMount() {
    this.getStation()
    this.getStatistics()
    this.getPackets()
    
  },
  methods: {
    async getStation() {
      const { data } = await axios.get(`${process.env.VUE_APP_API_ENDPOINT}/v1/station/${this.$route.params.id}`);
      console.log(data);
      if (!data.description)
        data.description = ""
      if (!data.pictures)
        data.pictures = []
      this.station = data;
      document.title = `${this.station.name} Console - TinyGS`
    },
    async getStatistics() {
      const { data } = await axios.get(`${process.env.VUE_APP_API_ENDPOINT}/v1/station/${this.$route.params.id}/statistics`);
      //console.log(data);
      this.statistics = data
      let today = new Date()
      today.setUTCHours(0,0,0,0);
      let telemetry = []
      let frames = []
      let labels = []
      let i = 0;
      for (let currDay = today - 1000 * 60 * 60 * 24 * 30; currDay <= today; currDay += 1000 * 60 * 60 * 24) {
        labels.push(new Date(currDay).getDate())
        
        if (data[i] && data[i].day == currDay) {
          let telemetryPkt_temp = (data[i].telemetryPkt || 0)
          telemetry.push(telemetryPkt_temp)
          frames.push(data[i].nFrames)
          i++
        }
        else {
          telemetry.push(0)
          frames.push(0)
        }
      }

      this.chartdata = {
        labels: labels,
        datasets: [
          { 
            type: 'bar',
            label: 'Telemetry packets',
            backgroundColor: '#1f83e6',
            data: telemetry,
            categoryPercentage: 0.5,
            barPercentage:1
          },
          {
            type: 'bar',
            label: 'Total packets',
            backgroundColor: '#84baf0',
            data: frames,
            categoryPercentage: 0.5,
            barPercentage:1
          }
        ]
      }

    },
    async getPackets() {
      this.loadingPackets = true
      if (this.packetLoadCount > 3 && new Date() - this.lastPacketLoad < 10000) {
        setTimeout(this.getPackets, 11000 - (new Date() - this.lastPacketLoad))
        return
      }
      this.packetLoadCount++
      let params = {
        station: this.$route.params.id
      }
      let config = {}
      if (this.packets.length > 0) {
        config = {
          headers: {
            sessionToken: localStorage.sessionToken,
            userId: localStorage.userId
          }
        }
        params.before = this.packets[this.packets.length-1].serverTime
      }
        
      const { data } = await axios.get(`${process.env.VUE_APP_API_ENDPOINT}/v1/packets`, { "params": params, "headers": config.headers});
      //console.log(data);
      if (!this.packets) this.packets = data
      else this.packets.push.apply(this.packets, data)
      this.loadingPackets = false
      this.lastPacketLoad = new Date()

      if (!this.packetsMap) this.packetsMap = data
    },
    dateSince(time) {
      if (!time) { return "Never"}
      return moment(time).fromNow()
    },
    isCurrentUser(user) {
      return localStorage.userId == user
    },
    configSent(){
      this.getStation()
    },
    // QTH locator calculations based on https://github.com/jleh/qth-locator
    // Credits to Juuso Lehtinen @ https://github.com/jleh
    latLngToLocator(lat, lng) { 
      const CHAR_CODE_OFFSET = 65;
      const numberToChar = number => String.fromCharCode(number + CHAR_CODE_OFFSET);
      const longitude = lng + 180;
      const latitude = lat + 90;

      const fieldLng = numberToChar(Math.floor(longitude / 20));
      const fieldLat = numberToChar(Math.floor(latitude / 10));

      const squareLng = Math.floor(longitude % 20 / 2);
      const squareLat = Math.floor(latitude % 10);

      const subsquareLng = numberToChar(Math.floor((longitude % 20 % 2) * 12)).toLowerCase();
      const subsquareLat = numberToChar((latitude % 10 - squareLat) * 24).toLowerCase();

      return fieldLng + fieldLat + squareLng + squareLat + subsquareLng + subsquareLat;
    },
    configSaved(config) {
      this.station.description = config.description
      this.station.antennaBand = config.antennaBand
      this.station.antenna = config.antenna
      this.station.pictures.push.apply(this.station.pictures, config.newFiles)
      this.editView=false;
    },
    configCancel(newFiles) {
      this.station.pictures.push.apply(this.station.pictures, newFiles)
      this.editView=false;
    }
  },
  computed: {
    confirmedPictures() {
      if (!this.station || !this.station.pictures)
        return null
      
      return this.station.pictures.filter(pic => pic.status == 1)
    }
  },
}
</script>

<style>
.credits-satvis {
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 9px;
  font-size: 0.7em;
  border-radius: 0 !important;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 2px;
}

.satvis-fullScreen {
  position: fixed;
  top: 65px;
  left:0;
  padding-bottom: 65px;
  height: 100%;
  width: 100%;
  z-index: 2;
}

.satvis-normal {
  display: block;
  width: 100%;
  height: 500px;
}

.fullScreen-btn-normal {
  position: absolute;
  right: 4px;
  bottom: 35px;
}

.fullScreen-btn-fullScreen {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 3;
}

</style>
